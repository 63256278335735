import React from "react";
import "./newOrders.css";
import Modal from "react-modal";
import FoodcourtLoader from "../foodcourtLoader";
import { globalVariables } from "../../Globals";
import { notification, Spin } from "antd";
import ReactPaginate from "react-paginate";
import { doneTypingInterval } from "../utility/const";
import { login, logout } from "../../redux/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { database } from "../../config/firebase";
import { onValue, ref } from "firebase/database";
import { AudioContext } from "../../audioContext";
import Api from "../../helper/apiHandler";

const api = new Api();

var moment = require("moment");

let { API_URL } = globalVariables;

class NewOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      newOrdersData: [],
      totalNewOrders: "",
      expandedRows: [],
      isRowCurrentlyExpanded: false,
      isopen: [],
      showModal: false,
      rejectReasonErrorMsg: "",
      dataSetId: "",
      loader: false,
      totalPages: 0,
      statusType: "new",
      statusLabel: "New",
      rejectReason: "",
      foodId: "",
      productList: "",
      prodData: [],
      currentPage: 0,
    };
    this.typingTimer = undefined; //timer identifier
    this.doneTypingInterval = doneTypingInterval; //time out
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmitModal = this.handleSubmitModal.bind(this);
    this.getNewOrders = this.getNewOrders.bind(this);
    this.acceptOrder = this.acceptOrder.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  static contextType = AudioContext;

  componentDidMount() {
    Modal.setAppElement("body");
    const ordersRef = ref(
      database,
      `counters/${this.props.user.id}/new_orders`
    );
    onValue(ordersRef, (snapshot) => {
      this.getNewOrders();
    });
    // ordersRef
    //   .child(`${this.props.user.id}/new_orders`)
    //   .on("child_changed", (snapshot) => {
    //     this.getNewOrders();
    //   });
  }

  getNewOrders = () => {
    const { search, currentPage } = this.state;
    const values = {
      name: search,
    };
    let _this = this;
    _this.setState({ loader: true });
    api
      .post(`/rest/list-new-orders?page=${currentPage}`, {
        data: values,
      })
      .then(function (res) {
        if (res.status === 200) {
          if (Object.keys(res.data).length !== 0) {
            _this.setState({
              newOrdersData: res.data.order_list,
              totalPages: res.data.totalPages,
              loader: false,
            });
          } else {
            _this.setState({
              newOrdersData: [],
              totalPages: 0,
              loader: false,
            });
          }
        } else if (res.response.data.status === 401) {
          // _this.openNotificationWithIcon("error", "Error", res.error);
          _this.props.history.push("/");
          _this.props.logout();
        } else {
          _this.setState({ loader: false });
          _this.openNotificationWithIcon("error", "Error", res.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  openNotificationWithIcon(type, head, desc) {
    notification[type]({
      message: head,
      description: desc,
      duration: 6,
    });
  }

  handleRowClick(rowId) {
    const temp = { ...this.state.isopen };
    temp[rowId] = true;
    this.setState({ isopen: temp });

    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);
    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowId)
      : currentExpandedRows.concat(rowId);

    this.setState({
      expandedRows: newExpandedRows,
      isRowCurrentlyExpanded: isRowCurrentlyExpanded,
    });
  }

  handleCloseClick(rowId) {
    const temp = { ...this.state.isopen };
    temp[rowId] = false;
    this.setState({ isopen: temp });

    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);
    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowId)
      : currentExpandedRows.concat(rowId);

    this.setState({
      expandedRows: newExpandedRows,
      isRowCurrentlyExpanded: isRowCurrentlyExpanded,
    });
  }

  handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * this.props.perPage);
    console.log("selected", selected);
    this.setState({ offset: offset, currentPage: selected }, () => {
      this.getRejectedOrders();
    });
  };

  renderItem(item) {
    const { isopen } = this.state;
    // var now = moment();
    const clickCallbackFalse = () => this.handleCloseClick(item.id);
    const clickCallback = () => this.handleRowClick(item.id);
    // console.log("Item", Date(item.updated_at).toString());
    const itemRows = [
      <tr className="main-accordion-row" key={"row-data-" + item.id}>
        <td className="orderid">#{item.foodcourt_order_id}</td>
        {/* <td>
          <div className="refresh-logo">
            <img  className="rotating" src="/images/refresh.png"/>
          </div>
        </td> */}
        <td>
          <span className="user-image">
            {item.user_image === "" ? (
              <img
                src="https://varshalteamprivatebucket.s3.ap-south-1.amazonaws.com/food_court/logo/default-image.png"
                // alt={"user" + item.id}
              />
            ) : (
              <img src={item.user_image} />
            )}
          </span>
          {item.user_name}
        </td>
        <td>
          {moment.utc(item.updated_at).local().format("MMM DD, YYYY, hh:mm A")}
        </td>
        <td>{item.items}</td>
        <td className="status">
          {/* <span className={item.statusType}>{item.status}</span> */}
          <span className={this.state.statusType}>
            {this.state.statusLabel}
          </span>
        </td>
        <td className="actions">
          <span data-id={item.id} className="accept" onClick={this.acceptOrder}>
            Accept
          </span>
          <span
            data-id={item.id}
            data-foodid={item.foodcourt_order_id}
            data-sub={item.productDisplayDtoList}
            data-productlist={JSON.stringify(item.productDisplayDtoList)}
            className="decline"
            onClick={this.handleOpenModal}
          >
            Decline
          </span>
          {!isopen[item.id] && (
            <span className="view" onClick={clickCallback}>
              View
            </span>
          )}
          {isopen[item.id] && (
            <span className="close" onClick={clickCallbackFalse}>
              Close
            </span>
          )}
        </td>
      </tr>,
    ];

    if (this.state.expandedRows.includes(item.id)) {
      let subItemData = item.productDisplayDtoList;
      const subItemRows = [];
      subItemData.forEach((subItem) => {
        const subItemRow = [
          <tr
            key={"sub-row-" + subItem.id}
            className="accordion-content-row-sub"
          >
            <td>
              <span className="food-image">
                {subItem.image === "" ? (
                  <img
                    src="/food-court/images/default-image.png"
                    // alt={"user" + subItem.id}
                  />
                ) : (
                  <img src={subItem.image} />
                )}
                {/* <img src={subItem.image} alt={"food-item" + subItem.id} /> */}
              </span>
              {subItem.name}
            </td>
            {subItem.selected_add_ons.length > 0 ? (
              <td style={{ textAlign: "left" }}>
                {subItem.selected_add_ons &&
                  subItem.selected_add_ons.map((e, index) => (
                    <>
                      <b key={e.id}>{e.title}: </b>
                      {e.types &&
                        e.types.map((x, index1) => (
                          <>
                            <>
                              <span key={x.id}>{x.option_title}</span>
                              {index1 + 1 === e.types.length ? <br /> : ","}
                            </>
                            {x.sub_types_option_title && (
                              <>
                                <b>{x.sub_types_option_title}: </b>
                                {x.sub_types &&
                                  x.sub_types.map((y, index2) => (
                                    <>
                                      <span key={y.id}>{y.product_title}</span>
                                      {index2 + 1 === x.sub_types.length ? (
                                        <br />
                                      ) : (
                                        ","
                                      )}
                                    </>
                                  ))}
                              </>
                            )}
                          </>
                        ))}
                    </>
                  ))}
              </td>
            ) : (
              <td style={{ textAlign: "left", width: "50%" }}>-</td>
            )}
            <td>{subItem.quantity}</td>
          </tr>,
        ];
        subItemRows.push(subItemRow);
      });

      itemRows.push(
        <tr key={"row-expanded-" + item.id} className="accordion-content-row">
          <td colSpan="6">
            <div className="inner-table-main">
              <table>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th style={{ textAlign: "left", padding: "0px" }}>
                      Specifications
                    </th>
                    <th>Qty</th>
                  </tr>
                </thead>
                <tbody>{subItemRows}</tbody>
              </table>
            </div>
          </td>
        </tr>
      );
    }
    return itemRows;
  }

  handleOpenModal(e) {
    this.setState({
      showModal: true,
      dataSetId: e.target.dataset.id,
      foodId: e.target.dataset.foodid,
      productList: e.target.dataset.productlist,
    });
    // console.log(
    //   "productlist======== ",
    //   JSON.parse(e.target.dataset.productlist)
    // );
    let productList = JSON.parse(e.target.dataset.productlist);
    productList.map((item, index) => {
      if (index === productList.length - 1) {
        var data = `${item.quantity + " x " + item.name}`;
        this.state.prodData.push(data);
      } else {
        var data = `${item.quantity + " x " + item.name + ", "}`;
        this.state.prodData.push(data);
      }
    });
  }

  handleCloseModal() {
    this.setState({ showModal: false, rejectReasonErrorMsg: "", prodData: [] });
  }

  onChange = (event) => {
    clearTimeout(this.typingTimer);
    this.setState({ search: event.target.value });
    // if(event.target.value!=null && event.target.value!=""){
    this.typingTimer = setTimeout(() => {
      this.getNewOrders();
    }, this.doneTypingInterval);
    // }
  };

  handleSubmitModal(e) {
    this.setState({ loader: true });
    e.preventDefault();
    console.log(e.target.reject_reason.value);
    // audio && audio.pause();
    var rejectReason = e.target.reject_reason.value;
    if (rejectReason === "" || rejectReason === null) {
      this.setState({
        rejectReasonErrorMsg: "Please enter a valid reason for rejection.",
        loader: false,
      });
    } else {
      if (this.context.audio && !this.context.audio.paused) {
        this.context.audio.pause();
        this.context.audio.currentTime = 0;
        this.context.timer && clearTimeout(this.context.timer);
      }
      this.setState({
        rejectReasonErrorMsg: "",
        rejectReason: rejectReason,
      });
      let _this = this;
      const values = {
        restaurant_id: this.props.user.id,
        restaurant_rejected_reason: e.target.reject_reason.value,
        order_id: parseInt(this.state.dataSetId),
      };
      _this.setState({ loader: true });
      api
        .post(`/rest/reject-order`, {
          data: values,
        })
        .then(function (res) {
          if (res.status === 200) {
            _this.setState({
              loader: false,
              showModal: false,
              rejectReasonErrorMsg: "",
              prodData: [],
            });
            _this.getNewOrders();
            _this.openNotificationWithIcon("success", "Success", res.message);
          } else if (res.response.data.status === 401) {
            // _this.openNotificationWithIcon("error", "Error", res.error);
            _this.setState({ loader: false });
            _this.props.history.push("/");
            _this.props.logout();
          } else {
            _this.setState({ loader: false });
            _this.openNotificationWithIcon("error", "Error", res.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  acceptOrder(e) {
    console.log(e.target.dataset.id);
    // audio && audio.pause();
    if (this.context.audio && !this.context.audio.paused) {
      this.context.audio.pause();
      this.context.audio.currentTime = 0;
      this.context.timer && clearTimeout(this.context.timer);
    }
    const values = {
      restaurant_id: this.props.user.id,
      order_id: parseInt(e.target.dataset.id),
    };
    let _this = this;
    this.setState({ loader: true });
    api
      .post(`/rest/accept-order`, {
        data: values,
      })
      .then(function (res) {
        if (res.status === 200) {
          _this.setState({
            loader: false,
            showModal: false,
            rejectReasonErrorMsg: "",
            prodData: [],
          });
          _this.openNotificationWithIcon("success", "Success", res.message);
        } else if (res.response.data.status === 401) {
          // _this.openNotificationWithIcon("error", "Error", res.error);
          _this.setState({ loader: false });
          _this.props.history.push("/");
          _this.props.logout();
        } else {
          _this.setState({ loader: false });
          _this.openNotificationWithIcon("error", "Error", res.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  resetFilter = () => {
    this.setState({ search: "" }, this.getNewOrders());
  };

  render() {
    let allItemRows = [];
    let { foodId, showModal, rejectReasonErrorMsg, newOrdersData, prodData } =
      this.state;

    newOrdersData.forEach((item) => {
      const perItemRows = this.renderItem(item);
      allItemRows = allItemRows.concat(perItemRows);
    });

    return (
      <>
        {this.state.loader == true ? <FoodcourtLoader /> : ""}
        <div className="new-orders">
          <div className="search-section custom-section">
            <input
              type="search"
              placeholder="Search by name"
              value={this.state.search}
              onChange={this.onChange}
              autoFocus
            />
            <button
              className="custom-reset-btn btn-reset"
              onClick={this.resetFilter}
            >
              Reset
            </button>
          </div>
          <div className="table-section">
            <table className="main-table">
              <thead>
                <tr>
                  <th>Order</th>
                  {/* <th></th> */}
                  <th>Name</th>
                  <th>Date</th>
                  <th>Item</th>
                  <th>status</th>
                  <th>action</th>
                </tr>
              </thead>
              {/* <tbody>{allItemRows}</tbody> */}
              {newOrdersData.length === 0 ? (
                <tbody>
                  <tr>
                    <td className="no_data" colSpan="6">
                      No orders found
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>{allItemRows}</tbody>
              )}
            </table>
            {this.state.totalPages > 0 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
          <Modal isOpen={showModal} contentLabel="Reject Order Modal">
            <div className="top-head-modal">
              <h3 className="order-id">#{foodId}</h3>
              <p className="order-details">
                {prodData}
                {/* 2 x Walnut Wrownie, 2 x Hot Flavor Tea , 1 x Mango Mania */}
                {/* {listItemsMap} */}
              </p>
            </div>
            <form
              className="reject-reason-form"
              onSubmit={this.handleSubmitModal}
            >
              <label>Reject Order</label>
              <textarea
                name="reject_reason"
                placeholder="Please mention the reason for rejection"
              />
              <span className="rejecterrormsg">{rejectReasonErrorMsg}</span>
              <div className="btn-section">
                <button>Reject</button>
                <button onClick={this.handleCloseModal}>Cancel</button>
              </div>
            </form>
          </Modal>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => {
    return {
      user: state.data.get("user"),
    };
  },
  { login, logout }
)(withRouter(NewOrders));
