import React from "react";
import logo from "../../assets/images/food-court-header-logo.png";
import { Menu, Dropdown, notification, Icon } from "antd";
import { Link, withRouter } from "react-router-dom";
import "./header.css";
import "antd/dist/antd.css";
import FoodcourtLoader from "../../components/foodcourtLoader";
import { connect } from "react-redux";
import { login, logout } from "../../redux/actions";
import appActions from "../../redux/actions/app/actions";
import Api from "../../helper/apiHandler";

const { toggleCollapsed } = appActions;

const api = new Api();

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      condition: false,
      loader: false,
      active_orders: 0,
      new_orders: 0,
    };
    this.dropOpen = this.dropOpen.bind(this);
    // this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.openNotificationWithIcon = this.openNotificationWithIcon.bind(this);
  }

  onHandleLogout = () => {
    let _this = this;
    _this.setState({ loader: true });
    api
      .post("/rest/restaurant-user-dashboard", {
        data: {},
      })
      .then(function (res) {
        if (res.status === 200) {
          _this.setState(
            {
              loader: false,
              active_orders: res.data.active_orders,
              new_orders: res.data.new_orders,
            },
            () => {
              _this.onOfflineRestaurant();
            }
          );
        } else if (res.response.data.status === 401) {
          // _this.openNotificationWithIcon("error", "Error", res.message);
          _this.props.logout();
          _this.props.history.push("/");
          _this.setState({ loader: false });
        } else {
          _this.openNotificationWithIcon("error", "Error", res.message);
          _this.setState({ loader: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onHandleChangePassword = () => {
    this.props.history.push("/change-password");
  };

  onLogout() {
    let _this = this;
    _this.setState({ loader: true });
    api
      .post("/logout-restaurant-user", {
        data: {},
      })
      .then(function (res) {
        if (res.status === 200) {
          _this.openNotificationWithIcon("success", "Success", res.message);
          _this.setState({ loader: false });
          _this.props.logout();
          // _this.props.history.push("/");
        } else if (res.response.data.status === 401) {
          // _this.openNotificationWithIcon("error", "Error", res.message);
          _this.props.history.push("/");
          _this.props.logout();
          _this.setState({ loader: false });
        } else {
          _this.openNotificationWithIcon("error", "Error", res.message);
          _this.setState({ loader: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onOfflineRestaurant() {
    const { active_orders, new_orders } = this.state;
    if (active_orders != 0 || new_orders != 0) {
      this.openNotificationWithIcon(
        "error",
        "Error",
        "You have new orders or active orders. Please complete or reject to logout."
      );
      this.setState({ loader: false });
      return;
    }
    let _this = this;
    _this.setState({ loader: true });
    api
      .post("/rest/set-restaurant-offline", {
        data: {},
      })
      .then(function (res) {
        if (res.status === 200) {
          _this.openNotificationWithIcon("success", "Success", res.message);
          _this.setState({ loader: false });
          _this.onLogout();
        } else if (res.response.data.status === 401) {
          // _this.openNotificationWithIcon("error", "Error", res.error);
          _this.setState({ loader: false });
          _this.props.history.push("/");
          _this.props.logout();
        } else if (res.status === 500) {
          _this.openNotificationWithIcon("error", "Error", res.message);
          _this.setState({ loader: false });
        } else {
          _this.setState({ loader: false });
          _this.openNotificationWithIcon("error", "Error", res.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  openNotificationWithIcon(type, head, desc) {
    notification[type]({
      message: head,
      description: desc,
      duration: 6,
    });
  }
  // handleClickOutside(e) {
  //   var checkClass = document.body.className;
  //   if (checkClass === "logoutpopupopen") {
  //     this.setState({
  //       condition: false
  //     });
  //     document.body.classList.remove("logoutpopupopen");
  //   }
  // }

  dropOpen() {
    this.setState({
      condition: !this.state.condition,
    });
    document.body.classList.add("logoutpopupopen");
  }

  render() {
    const menu = (
      <Menu>
        <Menu.Item key="1">
          <span className="logout" onClick={this.onHandleChangePassword}>
            <i className="fas fa-key" />
            Change Password
          </span>
        </Menu.Item>
        <Menu.Item key="0">
          <span className="logout" onClick={this.onHandleLogout}>
            <i className="fas fa-power-off" />
            Logout
          </span>
        </Menu.Item>
      </Menu>
    );
    return (
      <React.Fragment>
        <div className="header-main">
          <div className="header-inner wrapper-main">
            <div className="header-left">
            <a className="menuIconClick" onClick={this.props.toggleCollapsed}>
                  <i className="fa fa-bars" aria-hidden="true" />
                </a>
              <img src={logo} alt="Food Court" />
            </div>
            <div className="header-right">
              {/* <span className="action-icons">
              <Link to="/dashboard">
                <i className="fas fa-home" />
              </Link>
              <i className="fas fa-bell" />
            </span> */}
              <Dropdown overlay={menu} trigger={["click"]}>
                <p>
                  {/* <span>test</span> */}
                  <span>{this.props.user && this.props.user.name}</span>
                  <i className="fas fa-angle-down" />
                </p>
              </Dropdown>
              {/* <div
              onClick={this.dropOpen}
              className={
                this.state.condition ? "user-details open" : "user-details"
              }
              >
              <p>
              {/* <span>test</span> */}
              {/* <span>{this.props.user.name}</span>
                <i className="fas fa-angle-down" />
              </p> */}

              {/* <span className="logout" onClick={this.onLogout}>
                <i className="fas fa-power-off" />
                Logout
              </span> */}
              {/* </div> */}
              {/* <span className="user-image" /> */}
            </div>
          </div>
          {this.state.loader == true ? <FoodcourtLoader /> : ""}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => {
    return {
      user: state.data.get("user"),
    };
  },
  { login, logout, toggleCollapsed }
)(withRouter(Header));
