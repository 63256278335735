import React, { Component } from "react";
import { connect } from "react-redux";
import clone from "clone";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import options from "./options";
// import Scrollbars from "./customScrollBar.js";
// import IntlMessages from "../../components/utility/intlMessages";
import SidebarWrapper from "./sidebar.style";
import appActions from "../../redux/actions/app/actions";
import Logo from "../../components/utility/logo";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import { withRouter } from "react-router-dom";

// import authAction from "../../redux/auth/actions";
// const { login, storeToken } = authAction;

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

const { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed } =
  appActions;
const stripTrailingSlash = (str) => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentKey: null,
      is_admin: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }
  componentWillMount() {
    if (this.props.user) {
      const routes = window.location.pathname.split("/");
      if (routes.length > 1) {
        const lastRoute = routes[routes.length - 1];
        this.props.changeCurrent([lastRoute]);
      }
      // this.setState({
      //   is_admin: this.props.user.is_admin,
      //   is_shop_admin: this.props.is_shop_manager,
      // });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        currentKey: nextProps.location.pathname,
      });
    }
  }
  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      (key) => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      (key) => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = (key) => {
    const map = {
      sub3: ["sub2"],
    };
    return map[key] || [];
  };
  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { key, label, leftIcon, children } = singleOption;
    const url = stripTrailingSlash(this.props.url);
    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <span className="isoMenuHolder" style={submenuColor}>
              <i className={leftIcon} />
              <span className="nav-text">
                <span>{label}</span>
                {/* <IntlMessages id={label} /> */}
              </span>
            </span>
          }
        >
          {children.map((child) => {
            const linkTo = child.withoutDashboard
              ? `/${child.key}`
              : `${url}/${child.key}`;
            return (
              <Menu.Item style={submenuStyle} key={child.key}>
                <Link style={submenuColor} to={linkTo}>
                  <span>{label}</span>
                  {/* <IntlMessages id={child.label} /> */}
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key}>
        <Link to={`${url}/${key}`}>
          <span className="isoMenuHolder" style={submenuColor}>
            <i className={leftIcon} />
            <span className="nav-text">
              <span>{label}</span>
              {/* <IntlMessages id={label} /> */}
            </span>
          </span>
        </Link>
      </Menu.Item>
    );
  };
  render() {
    const { currentKey } = this.state;
    const { app, toggleOpenDrawer, height } = this.props;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? "vertical" : "inline";
    const onMouseEnter = (event) => {
      console.log(openDrawer);
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      console.log(openDrawer);
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
      return;
    };
    const customizedTheme = themes[themeConfig.theme];
    const styling = {
      backgroundColor: customizedTheme.backgroundColor,
    };
    const submenuStyle = {
      backgroundColor: "rgba(0,0,0,0.3)",
      color: customizedTheme.textColor,
    };
    const submenuColor = {
      color: customizedTheme.textColor,
    };
    return (
      <SidebarWrapper className="leftSidebar">
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width="240"
          className="isomorphicSidebar"
          // onMouseEnter={onMouseEnter}
          // onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={collapsed} />
          {/* <Scrollbars style={{ height: height - 70 }}> */}
          <Menu
            onClick={this.handleClick}
            theme="dark"
            className="isoDashboardMenu shopweb"
            mode={mode}
            openKeys={collapsed ? [] : app.openKeys}
            selectedKeys={currentKey}
            onOpenChange={this.onOpenChange}
          >
            {options.map((singleOption) => {
              // return (
              //   this.getMenuItem({ submenuStyle, submenuColor, singleOption })
              // )
              return this.getMenuItem({
                submenuStyle,
                submenuColor,
                singleOption,
              });
            })}
          </Menu>
          {/* </Scrollbars> */}
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default withRouter(
  connect(
    (state) => {
      return {
        app: state.app.toJS(),
        height: state.app.toJS().height,
        user: state.data.get("user"),
      };
    },
    { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
  )(Sidebar)
);
