var config = {
  apiKey: "AIzaSyC0-I0KLQjZWSFz-wvmOguYzJjIHJi1MVQ",
  authDomain: "foodcourt-user.firebaseapp.com",
  databaseURL: "https://foodcourt-user.firebaseio.com",
  projectId: "foodcourt-user",
  storageBucket: "foodcourt-user.appspot.com",
  messagingSenderId: "442830888508",
  appId: "1:442830888508:web:d7f37f8c81ef17876cad39",
  databaseURL: "https://foodcourt-user.firebaseio.com/"
  };

  export {config}