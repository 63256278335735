import React from "react";
import logo from "../../assets/images/login-page-logo.png";
import SimpleReactValidator from "simple-react-validator";
import { globalVariables } from "../../Globals";
import FoodcourtLoader from "../../components/foodcourtLoader";
import { notification } from "antd";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import Api from "../../helper/apiHandler";

const api = new Api();
let { API_URL } = globalVariables;

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      conf_password: "",
      loader: false,
    };
    this.validator1 = new SimpleReactValidator({
      matchPassword: {
        message: "New password and Confirm password does not match.",
        rule: (val) => {
          if (this.state.password === val) {
            return true;
          } else {
            return false;
          }
        },
      },
    });
    this.changePassword = this.changePassword.bind(this);
    this.changeConfPassword = this.changeConfPassword.bind(this);
    this.submit = this.submit.bind(this);
  }
  componentDidMount() {
    console.log(this.props.match.params.token);
  }

  changePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  changeConfPassword(e) {
    this.setState({
      conf_password: e.target.value,
    });
  }

  submit(e) {
    e.preventDefault();

    if (this.validator1.allValid()) {
      // alert("You submitted the form and stuff!");
      // this.setState({ loader: true });
      var values = {
        newPass: this.state.password,
        reset_code: this.props.match.params.token,
      };
      let _this = this;
      _this.setState({ loader: true });
      api
        .post(`/reset-password-website`, {
          data: values,
        })
        .then(function (res) {
          if (res.status === 200) {
            _this.openNotificationWithIcon("success", "Success", res.message);
            _this.setState({ loader: false });
            _this.props.history.push("/");
          } else {
            _this.openNotificationWithIcon("error", "Error", res.message);
            _this.setState({ loader: false });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.validator1.showMessages();
      this.forceUpdate();
    }
  }

  openNotificationWithIcon(type, head, desc) {
    notification[type]({
      message: head,
      description: desc,
      duration: 6,
    });
  }

  render() {
    return (
      <div className="login-page">
        <div className="login-page-inner">
          <div className="logo">
            <img src={logo} alt="Food Court" />
            <p>Enter new password and confirm it.</p>
          </div>
          <form className="forgot-password-form">
            <div className="floating-form">
              <div className="floating-label">
                <input
                  className="floating-input"
                  type="password"
                  placeholder=" "
                  value={this.state.password}
                  onChange={this.changePassword}
                />
                {this.validator1.message(
                  "password",
                  this.state.password,
                  "required|min:6|max:50",
                  "text-danger"
                )}
                {/* <span className="highlight" /> */}
                <label className="label_float">New Password</label>
              </div>
            </div>
            <div className="floating-form">
              <div className="floating-label">
                <input
                  className="floating-input"
                  type="password"
                  placeholder=" "
                  value={this.state.conf_password}
                  onChange={this.changeConfPassword}
                />
                {this.validator1.message(
                  "confirm password",
                  this.state.conf_password,
                  "required|min:6|max:50|matchPassword",
                  "text-danger"
                )}
                {/* <span className="highlight" /> */}
                <label className="label_float">Confirm Password</label>
              </div>
            </div>
            <div className="last-section last-section-forgot">
              <div className="link-forgot">
                <Link to="/">Back to Login</Link>
              </div>
            </div>
            <button onClick={this.submit} className="login-btn">
              Reset
            </button>
          </form>
        </div>
        {this.state.loader == true ? <FoodcourtLoader /> : ""}
      </div>
    );
  }
}

export default ResetPassword;
