import React, { Component } from "react";
import "./products.css";
import FoodcourtLoader from "../foodcourtLoader";
import {
  Button,
  Col,
  Icon,
  Modal,
  notification,
  Row,
  Spin,
  Tooltip,
  Select,
  Input,
  Switch,
} from "antd";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import { logout } from "../../redux/actions";
import { connect } from "react-redux";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../header/header";
import BannerComponent from "../bannerComponent/bannerComponent";
import { globalVariables } from "../../Globals";
import moment from "moment";
import ViewProductModal from "./viewProductModal";
import EditProductModal from "./editProductModal";
import LayoutWrapper from "../utility/layoutWrapper";
import Api from "../../helper/apiHandler";

const api = new Api();
const MySearchNote = styled.div`
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 12px;
  font-size: 11px;
`;
const { Option } = Select;
let { API_URL } = globalVariables;

var self;

class AllProducts extends Component {
  constructor(props) {
    super(props);
    //console.log("history", this.props.history);
    this.state = {
      selctedMealType: undefined,
      allSubCategories: [],
      allCategories: [],
      selctedSubCategory: undefined,
      selctedCategory: undefined,
      allProducts: [],
      allProductCount: 0,
      showAddProductModal: false,
      showEditProductModal: false,
      showViewProductModal: false,
      showDeleteProductModal: false,
      searchProduct: "",
      limit: process.env.REACT_APP_PER_PAGE_LIMIT,
      productDetails: [],
      deleteProductId: null,
      errMessage: "",
      errMsg: false,
      errType: "Success",
      page: 1,
      loader: false,
      currentId: null,
      restaurant_id: this.props.match.params.id,
      is_admin: false,
      is_restaurant_manager: false,
      currentPage: 0,
    };
    self = this;
    AllProducts.view = AllProducts.view.bind(this);
    AllProducts.edit = AllProducts.edit.bind(this);
    AllProducts.deleteProduct = AllProducts.deleteProduct.bind(this);
    AllProducts.changeStatus = AllProducts.changeStatus.bind(this);
  }

  static view(object) {
    self.setState({
      productDetails: object,
      showViewProductModal: true,
      page: 1,
    });
  }

  static edit(object) {
    self.setState({
      productDetails: object,
      showEditProductModal: true,
      page: 1,
    });
  }

  static changeStatus(object) {
    let is_active = object.is_active == 1 ? 0 : 1;

    let formData = {
      product_id: object.id,
      status: is_active,
    };

    self.setState({ loader: true });
    api
      .post("/rest/activate-deactivate-product", {
        data: formData,
      })
      .then(function (res) {
        if (res.status === 200) {
          self._getAllProducts();
          self.setState({
            errMsg: true,
            errMessage: res.message,
            errType: "Success",
            loader: false,
          });
        } else if (res.response.data.status === 401) {
          // self.openNotificationWithIcon("error", "Error", res.message);
          self.props.history.push("/");
          self.props.logout();
          self.setState({ loader: false });
        } else {
          self.openNotificationWithIcon("error", "Error", res.message);
          self.setState({ loader: false });
        }
      })
      .catch(() => {
        self.setState({
          errMsg: true,
          errMessage: "Something went wrong!!",
          searchUser: "",
          errType: "error",
          loader: false,
        });
      });
  }

  static deleteProduct(object) {
    self.setState({ showDeleteProductModal: true, deleteProductId: object.id });
  }

  componentDidMount = () => {
    this.setState(
      {
        userdata: this.props.user,
      },
      () => {
        this._getAllProducts();
        this._getAllCategories();
        this._getAllSubCategories();
      }
    );
  };

  openNotificationWithIconError = (type) => {
    notification[type]({
      message: this.state.errType,
      description: this.state.errMessage,
    });
    this.setState({ errMsg: false });
  };
  _getAllCategories = () => {
    const { searchUser } = this.state;
    let _this = this;

    let values = {
      name: searchUser,
    };
    _this.setState({ loader: true });
    api
      .post("/rest/all-food-categories-list", {
        data: values,
      })
      .then(function (res) {
        if (res.status === 200) {
          _this.setState({
            allCategories: res.data,
            loader: false,
          });
        } else if (res.response.data.status === 401) {
          // _this.openNotificationWithIcon("error", "Error", res.message);
          _this.props.history.push("/");
          _this.props.logout();
          _this.setState({ loader: false });
        } else {
          _this.openNotificationWithIcon("error", "Error", res.message);
          _this.setState({ loader: false });
        }
      })
      .catch((err) => {
        _this.setState({
          errMsg: true,
          errMessage: "Something went wrong!!",
          searchUser: "",
          errType: "error",
          loader: false,
        });
      });
  };
  _getAllSubCategories = () => {
    let _this = this;
    _this.setState({ loader: true });
    let values = {
      search: "",
    };
    api
      .post("/rest/all-sub-food-categories-list", {
        data: values,
      })
      .then(function (res) {
        if (res.status === 200) {
          _this.setState({
            allSubCategories: res.data,
            loader: false,
          });
        } else if (res.response.data.status === 401) {
          // _this.openNotificationWithIcon("error", "Error", res.message);
          _this.props.history.push("/");
          _this.props.logout();
          _this.setState({ loader: false });
        } else {
          _this.openNotificationWithIcon("error", "Error", res.message);
          _this.setState({ loader: false });
        }
      })
      .catch(() => {
        _this.setState({
          errMsg: true,
          errMessage: "Something went wrong!!",
          searchUser: "",
          errType: "error",
          loader: false,
        });
      });
  };
  _getAllProducts = () => {
    const {
      limit,
      searchProduct,
      page,
      selctedCategory,
      selctedMealType,
      selctedSubCategory,
      currentPage,
    } = this.state;
    var search_Product;

    if (selctedSubCategory || selctedCategory || selctedMealType) {
      search_Product = {
        name: searchProduct,
        is_filter: 1,
        category: selctedCategory,
        sub_category: selctedSubCategory,
        meal_type: selctedMealType,
      };
    } else {
      search_Product = {
        name: searchProduct,
      };
    }
    const values = {
      ...search_Product,
      name: search_Product.name,
      restaurant_id: this.state.userdata.id,
    };
    let _this = this;
    _this.setState({ loader: true });
    api
      .post(`/rest/view-all-products?page=${currentPage}`, {
        data: values,
      })
      .then(function (res) {
        if (res.status === 200) {
          if (Object.keys(res.data).length !== 0) {
            _this.setState({
              allProducts: res.data,
              is_online: res.is_online,
              totalPages: res.totalPages,
              loader: false,
            });
          } else {
            _this.setState({
              allProducts: [],
              totalPages: 0,
              loader: false,
              is_online: res.is_online,
            });
          }
        } else if (res.response.data.status === 401) {
          // _this.openNotificationWithIcon("error", "Error", res.error);
          _this.props.history.push("/");
          _this.props.logout();
        } else {
          _this.setState({ loader: false });
          _this.openNotificationWithIcon("error", "Error", res.error);
        }
      })
      .catch((error) => {
        console.log("this is error", error);
      });
  };

  _searchProduct = () => {
    this.setState({ page: 1 }, () => {
      this._getAllProducts();
    });
  };

  _resetSearch = () => {
    this.setState(
      {
        searchProduct: "",
        page: 1,
        selctedCategory: undefined,
        selctedSubCategory: undefined,
        selctedMealType: undefined,
      },
      () => {
        this._getAllProducts();
      }
    );
  };

  _changeSearch = (field, e) => {
    if (field.target.value.trim() == "") {
      field.target.value = "";
    } else {
      field.target.value = field.target.value;
    }
    this.setState({ searchProduct: field.target.value });
  };

  _handleProductPagination = (page) => {
    this.setState({ page }, () => {
      this._getAllProducts();
    });
  };

  _showAddProductModal = () => {
    //this.setState({ showAddProductModal: true });
    this.props.history.push("/products/add-products/" + this.state.userdata.id);
  };

  _closeViewProductModal = () => {
    this.setState({ showViewProductModal: false });
  };

  _closeEditProductModal = () => {
    this.setState({ showEditProductModal: false });
  };

  _closeAddProductModal = () => {
    this.setState({ showAddProductModal: false });
  };

  _deleteProduct = () => {
    const { deleteProductId } = this.state;
    let _this = this;

    const values = {
      product_id: deleteProductId,
    };

    _this.setState({ loader: true });
    api
      .post("/rest/delete-product", {
        data: values,
      })
      .then(function (res) {
        if (res.status === 200) {
          _this.setState({
            deleteProductId: null,
            showDeleteProductModal: false,
            errMessage: res.message,
            errMsg: true,
          });
          _this._getAllProducts();
        } else if (res.response.data.status === 401) {
          _this.setState({
            loader: false,
            showDeleteProductModal: false,
            deleteProductId: null,
          });
          // _this.openNotificationWithIcon("error", "Error", res.error);
          _this.props.history.push("/");
          _this.props.logout();
        } else {
          _this.setState({
            loader: false,
            showDeleteProductModal: false,
            deleteProductId: null,
          });
          _this.openNotificationWithIcon("error", "Error", res.error);
        }
      })
      .catch((error) => {
        console.log("this is error", error);
      });
  };
  // select sub category
  onChange = (value) => {
    this.setState({ selctedSubCategory: value }, () => {
      this._getAllProducts();
    });
  };
  // select Category
  onChangeMealType = (value) => {
    // console.log(`selected ${value}`);
    this.setState({ selctedMealType: value }, () => {
      this._getAllProducts();
    });
  };

  onBlurMealType = () => {
    // console.log('blur');
  };

  onFocusMealType = () => {
    // console.log('focus');
  };

  onSearchMealType = (val) => {
    // console.log('search:', val);
  };
  // select Category
  onChangeCategory = (value) => {
    // console.log(`selected ${value}`);
    this.setState({ selctedCategory: value }, () => {
      this._getAllProducts();
    });
  };

  onBlurCategory = () => {
    // console.log('blur');
  };

  onFocusCategory = () => {
    // console.log('focus');
  };

  onSearchCategory = (val) => {
    // console.log('search:', val);
  };
  _closeDeleteProductModal = () => {
    this.setState({ showDeleteProductModal: false });
  };

  handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * this.props.perPage);
    this.setState({ offset: offset, currentPage: selected }, () => {
      this._getAllProducts();
    });
  };

  renderItem(item) {
    const itemRows = [
      <tr className="main-accordion-row" key={"row-data-" + item.id}>
        {/* <td>
          <div className="refresh-logo">
            <img  className="rotating" src="/images/refresh.png"/>
          </div>
        </td> */}
        <td>
          <span className="user-image">
            {item.featured_image === "" ? (
              <img
                src="https://varshalteamprivatebucket.s3.ap-south-1.amazonaws.com/food_court/logo/default-image.png"
                // alt={"user" + item.id}
              />
            ) : (
              <img src={item.featured_image} />
            )}
          </span>
          {item.user_name}
        </td>
        <td className="orderid">{item.name}</td>
        <td className="orderid">{item.name_ar}</td>
        <td className="orderid">{item.price}</td>
        <td className="orderid">{item.food_categories_english_name}</td>
        <td>
          {moment.utc(item.updated_at).local().format("MMM DD, YYYY, hh:mm A")}
        </td>
        {/* <td className="status">
          <span className={this.state.statusType}>
            {this.state.statusLabel}
          </span>
        </td> */}
        <td className="actions">
          <Switch
            disabled
            checked={item.is_active == 0 ? false : true}
            style={{ borderRadius: "18px !important" }}
            onChange={() => {
              AllProducts.changeStatus(item);
            }}
          />
        </td>
        <td className="actions">
          <div>
            <Tooltip title="View">
              <Icon
                type="info-circle"
                style={{ marginLeft: "10px", cursor: "pointer" }}
                onClick={() => AllProducts.view(item)}
              />
            </Tooltip>
            {/* <Tooltip title="Edit">
              <Icon
                type="edit"
                style={{ marginLeft: "10px", cursor: "pointer" }}
                onClick={() => AllProducts.edit(item)}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Icon
                type="delete"
                onClick={() => AllProducts.deleteProduct(item)}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              />
            </Tooltip> */}
          </div>
        </td>
      </tr>,
    ];

    return itemRows;
  }

  render() {
    const {
      allProducts,
      allProductCount,
      showAddProductModal,
      productDetails,
      errType,
      loader,
      showViewProductModal,
      showEditProductModal,
      showDeleteProductModal,
      errMsg,
      page,
      limit,
      searchProduct,
      is_online,
    } = this.state;

    let allItemRows = [];
    if (allProducts.length !== 0) {
      // console.log("allProducts render", allProducts);
      allProducts.forEach((item) => {
        const perItemRows = this.renderItem(item);
        allItemRows = allItemRows.concat(perItemRows);
      });
      // alert("balank");
    }

    if (errMsg) {
      this.openNotificationWithIconError(errType.toLowerCase());
    }

    return (
      // <LayoutWrapper>
      <div
        className="new-orders"
        style={{ flexWrap: "wrap", margin: "15px auto" }}
      >
        {loader == true ? <FoodcourtLoader /> : ""}
        {/* {is_online === 1 ? (
          <Row>
            <Col span={3}>
              <button
                className="custom-reset-btn btn-reset"
                style={{
                  margin: "14px",
                  width: "100%",
                }}
                onClick={this._showAddProductModal.bind(this)}
              >
                Add Product
              </button>

              <MySearchNote>
                <b>Note</b> : Restaurant is online.
              </MySearchNote>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={3}>
              <Button
                className="search-btn"
                type="primary"
                style={{
                  margin: "14px",

                  width: "100%",
                }}
                onClick={this._showAddProductModal.bind(this)}
              >
                Add Product
              </Button>

              <MySearchNote>
                <b>Note</b> : Restaurant is offline.
              </MySearchNote>
            </Col>
          </Row>
        )} */}
        <Row gutter={16} style={{ margin: "0 5px" }}>
          <Col span={5}>
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Select Cuisine"
              optionFilterProp="children"
              onChange={this.onChangeCategory}
              value={this.state.selctedCategory}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.allCategories.map(function (temp) {
                return <Option value={temp.id}>{temp.name}</Option>;
              })}
            </Select>
          </Col>
          {/* select sub category */}
          <Col span={5}>
            <Select
              // mode="multiple"
              showSearch
              style={{ width: "100%" }}
              placeholder="Select Category"
              optionFilterProp="children"
              onChange={this.onChange}
              value={this.state.selctedSubCategory}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.allSubCategories.map(function (temp) {
                return <Option value={temp.id}>{temp.name}</Option>;
              })}
            </Select>
          </Col>
          <Col span={7}>
            <Input
              placeholder="Search Products"
              style={{ width: "100%" }}
              onChange={this._changeSearch.bind(this)}
              onKeyPress={(e) => {
                if (e.key == "Enter") {
                  this._searchProduct();
                }
              }}
              value={searchProduct}
            />
            <MySearchNote>
              <b>Note</b> : You can search Products via Name and Type.
            </MySearchNote>
          </Col>
          <Col span={2}>
            <Button
              type="primary"
              style={{ width: "100%", margin: "0" }}
              // className="custom-reset-btn btn-reset"
              onClick={this._searchProduct.bind(this)}
            >
              Search
            </Button>
          </Col>
          <Col span={2}>
            <Button
              type="primary"
              style={{ width: "100%", margin: "0" }}
              // className="custom-reset-btn btn-reset"
              onClick={this._resetSearch}
            >
              Reset
            </Button>
          </Col>
        </Row>
        {showViewProductModal && (
          <ViewProductModal
            productDetails={productDetails}
            showViewProductModal={showViewProductModal}
            closeViewProductModal={this._closeViewProductModal}
          />
        )}
        {showEditProductModal && (
          <EditProductModal
            fields={productDetails}
            showEditProductModal={showEditProductModal}
            closeEditProductModal={this._closeEditProductModal}
            getAllProducts={this._getAllProducts.bind(this, 1)}
          />
        )}

        {showDeleteProductModal && (
          <Modal
            maskClosable={false}
            title="Delete Product"
            visible={showDeleteProductModal}
            onCancel={this._closeDeleteProductModal}
            footer={[
              <Button onClick={this._closeDeleteProductModal}>No</Button>,
              <Button onClick={this._deleteProduct}>Yes</Button>,
            ]}
          >
            Are you sure you want to delete this Product ?
          </Modal>
        )}
        <div className="table-section">
          <table className="main-table">
            <thead>
              <tr>
                {/* <th></th> */}
                <th>Product Image</th>
                <th>Product Name</th>
                <th>Product Name(Arabic)</th>
                <th>Product Price(in SAR)</th>
                <th>food Categories</th>
                <th>Date</th>
                <th>Active</th>
                <th>action</th>
              </tr>
            </thead>
            {this.state.allProducts.length === 0 ? (
              <tbody>
                <tr>
                  <td className="no_data" colSpan="8">
                    No Products found
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>{allItemRows}</tbody>
            )}
          </table>
          {this.state.totalPages > 0 && (
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          )}
        </div>
      </div>
      // </LayoutWrapper>
    );
  }
}

export default connect(
  (state) => ({
    user: state.data.get("user"),
  }),
  { logout }
)(AllProducts);

export { AllProducts };
