import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import login from "../components/login/login";
import forgotPassword from "../components/forgotPassword/forgotPassword";
import resetPassword from "../components/resetPassword/resetPassword";
import dashboard from "../components/dashboard/dashboard";
import notFound from "../components/notFound/notFound";
import PrivateRoute from "./privateRoute";
import ChangePassword from "../components/changePassword/changePassword";
import Products from "../components/product/products";

const AppRouter = ({ history }) => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={login} />
        <Route exact path="/forgot-password" component={forgotPassword} />
        <Route exact path="/reset-password/:token" component={resetPassword} />
        <PrivateRoute exact path="/dashboard" component={dashboard} />
        <PrivateRoute
          exact
          path="/change-password"
          component={ChangePassword}
        />
        <PrivateRoute exact path="/products" component={Products} />
        <Route component={notFound} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
