import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { notification } from "antd";
import LayoutWrapper from "../components/utility/layoutWrapper";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/header/header";
import appActions from "../redux/actions/app/actions";
import { messaging } from "../config/firebase";
import newOrderTune from "../assets/new-order.mp3";
import silence from "../assets/autoPlay.mp3";
import { onMessage } from "firebase/messaging";
import AudioContextProvider from "../audioContext";

const broadcast = new BroadcastChannel("order");
const { toggleCollapsed } = appActions;

const PrivateRoute = ({ component: Component, user, ...rest }) => {
  const [audio] = useState(new Audio(newOrderTune));
  const [timer, setTimer] = useState(null);

  const handleAudio = () => {
    if (!audio.paused) {
      audio.pause();
      audio.currentTime = 0;
      timer && clearTimeout(timer);
      setTimer(null);
      playAudio();
    } else {
      playAudio();
    }
  };

  const playAudio = () => {
    audio.autoplay = true;
    audio.muted = false;
    audio.loop = true;
    audio.play();
    const timer = setTimeout(() => {
      audio && audio.pause();
      audio.currentTime = 0;
      timer && clearTimeout(timer);
      setTimer(null);
    }, 1000 * 60);
    setTimer(timer);
  };

  const resetAudioSettings = () => {
    if (!audio?.paused) {
      audio && audio.pause();
      audio.currentTime = 0;
      audio.muted = true;
      timer && clearTimeout(timer);
      setTimer(null);
    }
  };

  useEffect(() => {
    resetAudioSettings();
    broadcast.onmessage = (event) => {
      if (event.data.type === "NEW_ORDER") {
        handleAudio();
      }
    };
    onMessage(messaging, (payload) => {
      if (payload.data.type === "NEW_ORDER") {
        notification.info({
          message: payload.data.title,
          description: payload.data.body,
        });
        handleAudio();
      }
    });

    return () => {
      resetAudioSettings();
    };
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        user || JSON.parse(localStorage.getItem("state")).user ? (
          <AudioContextProvider data={{ audio, timer }}>
            <iframe
              src={silence}
              allow="autoplay"
              id="audio"
              style={{ display: "none" }}
            ></iframe>
            <div
              style={{ display: "flex", gap: "20px" }}
              className={rest.collapsed ? "hide" : "show"}
            >
              <Sidebar url={""} />
              <div className="dashboard-page rightSidebar">
                <Header />
                <LayoutWrapper>
                  <Component {...props} />
                </LayoutWrapper>
              </div>
            </div>
          </AudioContextProvider>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default connect(
  (state) => {
    return {
      user: state.data.get("user"),
      collapsed: state.app.get("collapsed"),
    };
  },
  { toggleCollapsed }
)(PrivateRoute);
