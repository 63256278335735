import React from "react";
import logo from "../../assets/images/login-page-logo.png";
import { Link, Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import FoodcourtLoader from "../../components/foodcourtLoader";
import { notification } from "antd";
import { connect } from "react-redux";
import "antd/dist/antd.css";
import "./login.css";
import { login } from "../../redux/actions";
import { getFCMToken } from "../../config/firebase";
import Api from "../../helper/apiHandler";

const api = new Api();

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      email: "",
      password: "",
      loader: false,
      redirectToReferrer: false,
    };
    this.handleCheck = this.handleCheck.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.submit = this.submit.bind(this);
    this.openNotificationWithIcon = this.openNotificationWithIcon.bind(this);
    this.validator = new SimpleReactValidator({
      validEmail: {
        // name the rule
        message: "Please enter valid email address.", // give a message that will display when there is an error. :attribute will be replaced by the name you supply in calling it.
        rule: function (val, options) {
          // return true if it is succeeds and false it if fails validation. the _testRegex method is available to give back a true/false for the regex and given value
          // check that it is a valid IP address and is not blacklisted
          var re =
            /^[-a-zA-Z0-9~!$%^&*_=+}{\'?]+(\.[-a-zA-Z0-9~!$%^&*_=+}{\'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|AERO|ARPA|BIZ|COM|COOP|EDU|GOV|INFO|INT|MIL|MUSEUM|NAME|NET|ORG|PRO|TRAVEL|MOBI|[a-zA-Z][a-zA-Z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/;
          var bool = re.test(String(val).toLowerCase());
          return bool;
        },
      },
    });
  }
  componentDidMount(){
    if(JSON.parse(localStorage.getItem('state'))?.user || this.props.user){
      this.setState({
        redirectToReferrer: true
      })
    }
  }

  handleCheck(e) {
    this.setState({ checked: !this.state.checked });
  }
  changeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }
  changePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }
  async submit(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      // alert("You submitted the form and stuff!");
      let _this = this;
      _this.setState({ loader: true });
      const fcmToken = await getFCMToken();
      var values = {
        email: _this.state.email,
        password: _this.state.password,
        fcm_token: fcmToken ?? undefined,
      };
      api
        .post("/login-restaurant-user", {
          data: values,
        })
        .then(function (res) {
          if (res.status === 200) {
            _this.openNotificationWithIcon("success", "Success", res.message);
            _this.props.login(res.data);
            _this.props.history.push("/dashboard");
            _this.setState({ loader: false });
          } else {
            _this.openNotificationWithIcon("error", "Error", res.message);
            _this.setState({ loader: false });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  openNotificationWithIcon(type, head, desc) {
    notification[type]({
      message: head,
      description: desc,
      duration: 6,
    });
  }
  render() {
    // var msg;
    // if (this.state.checked) {
    //   msg = "checked";
    // } else {
    //   msg = "unchecked";
    // }
    if (this.state.redirectToReferrer) {
      const { from } = this.props.location.state || { from: { pathname: '/dashboard' } };
      return <Redirect to={from} />;
    }

    return (
      <div className="login-page">
        <div className="login-page-inner">
          <div className="logo">
            <img src={logo} alt="Food Court" />
            <p>Welcome Back, Please login to your account</p>
          </div>
          <form className="Login-form">
            <div className="floating-form">
              <div className="floating-label">
                <input
                  className="floating-input"
                  type="email"
                  placeholder=" "
                  value={this.state.email}
                  onChange={this.changeEmail}
                />
                {this.validator.message(
                  "email",
                  this.state.email,
                  "required|validEmail",
                  "text-danger"
                )}
                {/* <span className="highlight" /> */}
                <label className="label_float">Email/Mobile</label>
              </div>
            </div>
            <div className="floating-form">
              <div className="floating-label">
                <input
                  className="floating-input"
                  type="password"
                  placeholder=" "
                  value={this.state.password}
                  onChange={this.changePassword}
                />
                {this.validator.message(
                  "password",
                  this.state.password,
                  "required|min:6|max:50",
                  "text-danger"
                )}
                {/* <span className="highlight" /> */}
                <label className="label_float">Password</label>
              </div>
            </div>
            <div className="last-section">
              {/* <div className="form-group">
                <label className="container">
                  Remember me
                  <input
                    type="checkbox"
                    onChange={this.handleCheck}
                    defaultChecked={this.state.checked}
                  />
                  <span className="checkmark" />
                </label>
                {/* {msg} */}
              {/* </div> */}
              <div className="link-forgot">
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
            </div>
            {/* <Link to="/dashboard"> */}
            <button onClick={this.submit} className="login-btn">
              Login
            </button>{" "}
            {/* </Link> */}
          </form>
        </div>
        {this.state.loader == true ? <FoodcourtLoader /> : ""}
      </div>
    );
  }
}

export default connect((state) => {
    return {
      user: state.data.get("user"),
    };
  }, { login })(Login);
