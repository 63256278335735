import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { notification } from "antd";
import NewOrders from "../newOrders/newOrders";
import ActiveOrders from "../activeOrders/activeOrders";
import CompletedOrders from "../completedOrders/completedOrders";
import RejectedOrders from "../rejectedOrders/rejectedOrders";
import FoodcourtLoader from "../foodcourtLoader";
import * as actions from "../../redux/actions";
import { database } from "../../config/firebase";
import { onChildChanged, onValue, query, ref } from "firebase/database";
import { AudioContext } from "../../audioContext";
import Api from "../../helper/apiHandler";

const api = new Api();

class Card extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      loader: false,
      showNewComponent: true,
      showActiveComponent: false,
      showCompletedComponent: false,
      showRejectedComponent: false,
      isactive: [],
      userdata: props.user,
      activeOrderStatus: "Waiting",
      items: [
        {
          id: 1,
          name: "New Orders",
          dataId: "new",
        },
        {
          id: 2,
          name: "Active Orders",
          dataId: "active",
        },
        {
          id: 3,
          name: "Completed Orders",
          dataId: "completed",
        },
        {
          id: 4,
          name: "Rejected Orders",
          dataId: "rejected",
        },
      ],
      cardData: "",
    };
    this.onButtonClick = this.onButtonClick.bind(this);
    this.child = React.createRef();
    // this.getCardDetails = this.getCardDetails.bind(this);
  }

  static contextType = AudioContext;

  componentDidMount() {
    const temp = { ...this.state.isactive };
    const is_online = this.state.userdata.is_online;
    temp["active"] = is_online ? false : true;
    temp["new"] = is_online ? true : false;
    temp["completed"] = false;
    temp["rejected"] = false;
    this.setState({ isactive: temp });
    this.setState({ showNewOrder: this.props.isShowNewOrder ? true : false });
    const ordersRef = ref(database, `counters/${this.state.userdata.id}`);
    onValue(ordersRef, (snapshot) => {
      this.getCardDetails();
    });
    onChildChanged(query(ordersRef), (snapshot) => {
      if (
        snapshot.key === "rejected_orders" ||
        snapshot.key === "active_orders"
      ) {
        if (this.context.audio && !this.context.audio.paused) {
          this.context.audio.pause();
          this.context.audio.currentTime = 0;
          this.context.timer && clearTimeout(this.context.timer);
        }
      }
    });

    // ordersRef.child(`${this.props.user.id}`).on("child_changed", (snapshot) => {
    //   console.log("safdsafasfa");
    //   this.getCardDetails();
    // });
  }

  getCardDetails() {
    let _this = this;
    _this.setState({ loader: true });
    api
      .post(`/rest/restaurant-user-dashboard`, {
        data: {},
      })
      .then(function (res) {
        if (res.status === 200) {
          _this.setState({
            cardData: res.data,
            loader: false,
          });
        } else if (res.response.data.status === 401) {
          // _this.openNotificationWithIcon("error", "Error", res.message);
          _this.props.history.push("/");
          _this.props.logout();
          _this.setState({ loader: false });
        } else {
          _this.openNotificationWithIcon("error", "Error", res.message);
          _this.setState({ loader: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  openNotificationWithIcon(type, head, desc) {
    notification[type]({
      message: head,
      description: desc,
      duration: 6,
    });
  }
  onButtonClick(e) {
    this.setState({ loader: true });
    var dataId = e.currentTarget.dataset.id;
    // this.getCardDetails();
    if (dataId === "new") {
      const temp = { ...this.state.isactive };
      temp[dataId] = true;
      temp["active"] = false;
      temp["completed"] = false;
      temp["rejected"] = false;
      this.setState({
        showNewComponent: true,
        showActiveComponent: false,
        showCompletedComponent: false,
        showRejectedComponent: false,
        isactive: temp,
        loader: false,
      });
    } else if (dataId === "active") {
      const temp = { ...this.state.isactive };
      temp[dataId] = true;
      temp["new"] = false;
      temp["completed"] = false;
      temp["rejected"] = false;
      this.setState({
        showNewComponent: false,
        showActiveComponent: true,
        showCompletedComponent: false,
        showRejectedComponent: false,
        isactive: temp,
        loader: false,
      });
    } else if (dataId === "completed") {
      const temp = { ...this.state.isactive };
      temp[dataId] = true;
      temp["new"] = false;
      temp["active"] = false;
      temp["rejected"] = false;
      this.setState({
        showNewComponent: false,
        showActiveComponent: false,
        showCompletedComponent: true,
        showRejectedComponent: false,
        isactive: temp,
        loader: false,
      });
    } else if (dataId === "rejected") {
      const temp = { ...this.state.isactive };
      temp[dataId] = true;
      temp["new"] = false;
      temp["completed"] = false;
      temp["active"] = false;
      this.setState({
        showNewComponent: false,
        showActiveComponent: false,
        showCompletedComponent: false,
        showRejectedComponent: true,
        isactive: temp,
        loader: false,
      });
    } else {
      const temp = { ...this.state.isactive };
      temp["active"] = false;
      temp["new"] = false;
      temp["completed"] = false;
      temp["rejected"] = false;
      this.setState({ isactive: temp, loader: false });
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.notification_id !== nextProps.notification_id) {
  //     if (nextProps.notification_id.toString().search(/NewOrder/gi) != -1) {
  //       const temp = { ...this.state.isactive };
  //       temp["active"] = false;
  //       temp["new"] = true;
  //       temp["completed"] = false;
  //       temp["rejected"] = false;
  //       this.setState({
  //         isactive: temp,
  //         loader: false,
  //         showNewComponent: true,
  //         showActiveComponent: false,
  //         showCompletedComponent: false,
  //         showRejectedComponent: false,
  //       });
  //     } else if (
  //       nextProps.notification_id.toString().search(/ActiveOrder/gi) != -1
  //     ) {
  //       const temp = { ...this.state.isactive };
  //       temp["active"] = true;
  //       temp["new"] = false;
  //       temp["completed"] = false;
  //       temp["rejected"] = false;
  //       this.setState({
  //         isactive: temp,
  //         loader: false,
  //         showNewComponent: false,
  //         showActiveComponent: true,
  //         showCompletedComponent: false,
  //         showRejectedComponent: false,
  //       });
  //       if (nextProps.notification_id.toString().search(/Preparing/gi) != -1) {
  //         this.setState({ activeOrderStatus: "preparing" });
  //       } else if (
  //         nextProps.notification_id.toString().search(/moved/gi) != -1
  //       ) {
  //         this.setState({ activeOrderStatus: "moved" });
  //       }
  //     }
  //     this.getCardDetails();
  //   }
  //   this.setState({ userdata: nextProps.user });
  // }

  // onAction = () => {
  //   this.getCardDetails();
  // };

  render() {
    var { isLoaded, items, isactive, cardData, activeOrderStatus } = this.state;
    return (
      <div className={this.props.is_online ? "" : "overlayon"}>
        <div className="card-section-inner">
          {items.map((item) => (
            <div
              className="card-main"
              key={item.id}
              data-id={item.dataId}
              onClick={this.onButtonClick}
            >
              <div
                className={
                  isactive[item.dataId]
                    ? "card-main-inner active"
                    : "card-main-inner"
                }
              >
                <div className="icon-block">
                  <i className="fas fa-cubes" />
                </div>
                <div className="content-box">
                  <h3>{item.name}</h3>
                  {item.dataId === "new" ? (
                    <span>{cardData.new_orders}</span>
                  ) : (
                    ""
                  )}
                  {item.dataId === "active" ? (
                    <span>{cardData.active_orders}</span>
                  ) : (
                    ""
                  )}
                  {item.dataId === "completed" ? (
                    <span>{cardData.completed_orders}</span>
                  ) : (
                    ""
                  )}
                  {item.dataId === "rejected" ? (
                    <span>{cardData.rejected_orders}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        {this.state.showNewComponent && (
          <NewOrders count={cardData.new_orders} />
        )}
        {this.state.showActiveComponent && (
          <ActiveOrders status={activeOrderStatus} />
        )}
        {this.state.showCompletedComponent && <CompletedOrders />}
        {this.state.showRejectedComponent && <RejectedOrders />}

        <div id="overlay">
          <div id="text">
            You are currently offline and not receiving orders.
            <br /> Please go online to receive orders.
          </div>
        </div>
        {this.state.loader == true ? <FoodcourtLoader /> : ""}
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({ data }) => ({
      notification_id: data.get("notification_id"),
      user: data.get("user"),
      is_online: data.get("user").is_online,
    }),
    actions
  )(Card)
);
