import { initializeApp } from 'firebase/app';
import { onMessage, getToken, getMessaging, deleteToken } from 'firebase/messaging';
import { getDatabase } from 'firebase/database';
import { config } from "./config";

const firebaseApp = initializeApp(config);

const messaging = getMessaging(firebaseApp);

export async function getFCMToken() {
  // try {
  //   const currentToken = await getToken(messaging, {
  //     vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
  //   });
  //   if (currentToken) {
  //     // console.log('current token for client: ', currentToken);
  //     localStorage.setItem('fcm', currentToken);
  //   } else {
  //     // console.log('No registration token available. Request permission to generate one.');
  //     localStorage.removeItem('fcm');
  //   }
  // } catch (err) {
  //   // console.log('An error occurred while retrieving token. ', err);
  //   localStorage.removeItem('fcm');
  // }
  try {
    const permission = await Notification.requestPermission()
    if(permission === 'granted'){
      const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
      });
      if (currentToken) {
        return currentToken;
        // console.log('current token for client: ', currentToken);
      } else {
        return undefined;
        // console.log('No registration token available. Request permission to generate one.');
      }
    }else {
      return undefined;
    }
  } catch (error) {
    console.log(error)
    return undefined;
  }
}

export async function deleteFCMToken() {
  try {
    deleteToken(messaging, payload => {});
  } catch (error) {
    console.log(error);
  }
}

// export const onMessageListener = () =>
//   new Promise(resolve => {
//     onMessage(messaging, payload => {
//       console.log(payload)
//       // console.log('Message received in foreground. ', payload);
//       // navigator.serviceWorker
//       //   .getRegistration('/firebase-cloud-messaging-push-scope')
//       //   .then(registration => {
//       //     registration.showNotification(payload?.notification.title, payload?.notification);
//       //   });
//       resolve(payload);
//     });
//   });

const database = getDatabase(firebaseApp);


export {messaging, database}

// export const notificationsRef = databaseRef.child("notifications");
// export const ordersRef = databaseRef.child("counters");
