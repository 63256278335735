import React from "react";
import logo from "../../assets/images/login-page-logo.png";
import SimpleReactValidator from "simple-react-validator";
import FoodcourtLoader from "../../components/foodcourtLoader";
import { notification } from "antd";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import Api from "../../helper/apiHandler";

const api = new Api();

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loader: false,
    };
    this.validator1 = new SimpleReactValidator({
      validEmail: {
        // name the rule
        message: "Please enter valid email address.", // give a message that will display when there is an error. :attribute will be replaced by the name you supply in calling it.
        rule: function (val, options) {
          // return true if it is succeeds and false it if fails validation. the _testRegex method is available to give back a true/false for the regex and given value
          // check that it is a valid IP address and is not blacklisted
          var re =
            /^[-a-zA-Z0-9~!$%^&*_=+}{\'?]+(\.[-a-zA-Z0-9~!$%^&*_=+}{\'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|AERO|ARPA|BIZ|COM|COOP|EDU|GOV|INFO|INT|MIL|MUSEUM|NAME|NET|ORG|PRO|TRAVEL|MOBI|[a-zA-Z][a-zA-Z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/;
          var bool = re.test(String(val).toLowerCase());
          return bool;
        },
      },
    });
    this.changeEmail = this.changeEmail.bind(this);
    this.submit = this.submit.bind(this);
    this.clearValidation = this.clearValidation.bind(this);
  }
  componentDidMount() {
    console.log(this.props);
  }

  changeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }
  clearValidation() {
    this.validator1.hideMessages();
    this.forceUpdate();
  }
  submit(e) {
    e.preventDefault();
    if (this.validator1.allValid()) {
      // alert("You submitted the form and stuff!");
      // this.setState({ loader: true });
      var values = {
        email: this.state.email,
      };
      let _this = this;
      _this.setState({ loader: true });
      api
        .post(`/forgot-password-restaurant`, {
          data: values,
        })
        .then(function (res) {
          if (res.status === 200) {
            _this.openNotificationWithIcon("success", "Success", res.message);
            _this.setState({ loader: false, email: "" });
            _this.clearValidation();
          } else {
            _this.openNotificationWithIcon("error", "Error", res.message);
            _this.setState({ loader: false });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.validator1.showMessages();
      this.forceUpdate();
    }
  }

  openNotificationWithIcon(type, head, desc) {
    notification[type]({
      message: head,
      description: desc,
      duration: 6,
    });
  }

  render() {
    return (
      <div className="login-page">
        <div className="login-page-inner">
          <div className="logo">
            <img src={logo} alt="Food Court" />
            <p>Please enter email/mobile to reset your account password</p>
          </div>
          <form className="forgot-password-form">
            <div className="floating-form">
              <div className="floating-label">
                <input
                  className="floating-input"
                  type="email"
                  placeholder=" "
                  value={this.state.email}
                  onChange={this.changeEmail}
                />
                {this.validator1.message(
                  "email",
                  this.state.email,
                  "required|validEmail",
                  "text-danger"
                )}
                <label className="label_float">Email/Mobile</label>
              </div>
            </div>
            <div className="last-section last-section-forgot">
              <div className="link-forgot">
                <Link to="/">Back to Login</Link>
              </div>
            </div>
            <button onClick={this.submit} className="login-btn">
              Send
            </button>
          </form>
        </div>
        {this.state.loader == true ? <FoodcourtLoader /> : ""}
      </div>
    );
  }
}

export default ForgotPassword;
