import React from "react";
import { Link } from "react-router-dom";
import { siteConfig } from "../../settings";
import logo from "../../assets/images/food-court-icon.png";
export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper sidebarLogo">
      {collapsed ? (
        <div>
          <h3>
            <Link
              to={{
                hash: "#",
              }}
            >
              <img
                src={logo}
                alt="Icon"
                style={{ height: "30px", width: "30px" }}
              />
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link
            to={{
              hash: "#",
            }}
          >
            <span>{siteConfig.siteName}</span>
          </Link>
        </h3>
      )}
    </div>
  );
};
