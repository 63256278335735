import React, { Component } from "react";
import { Modal, Button, Row, Col } from "antd";

class ViewProductModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showViewProductModal: this.props.showViewProductModal,
      imagesArray: this.props.productDetails.imagesArray,
    };
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.showViewProductModal !== prevState.showViewProductModal) {
      return {
        showViewProductModal: nextProps.showViewProductModal,
        imagesArray: nextProps.productDetails.imagesArray,
      };
    }
    return null;
  };

  _closeViewProductModal = () => {
    this.setState({ showViewProductModal: false });
    this.props.closeViewProductModal();
  };

  render() {
    const { productDetails } = this.props;
    console.log(productDetails);
    const { showViewProductModal, imagesArray } = this.state;

    return (
      <Modal
        maskClosable={false}
        width="800px"
        title="View Product"
        visible={showViewProductModal}
        onCancel={this._closeViewProductModal}
        footer={[<Button onClick={this._closeViewProductModal}>OK</Button>]}
      >
        <span>
          {" "}
          <b>Featured Image:</b>{" "}
        </span>
        <br />
        {productDetails.featured_image ? (
          <img
            style={{ width: "120px", height: "120px" }}
            src={productDetails.featured_image}
          />
        ) : (
          ""
        )}
        <br />
        <br />

        <span>
          {" "}
          <b>Other Images:</b>{" "}
        </span>
        <br />
        {imagesArray ? (
          <div>
            {imagesArray &&
              imagesArray.length > 0 &&
              imagesArray.map((prod) => {
                return (
                  <img
                    style={{ width: "120px", height: "120px", margin: "5px" }}
                    src={prod}
                  />
                );
              })}
            {(!imagesArray || imagesArray.length == 0) && (
              <p>No Images Available</p>
            )}
          </div>
        ) : (
          "sdg"
        )}
        <br />

        <Row>
          <Col span={11}>
            <span>
              {" "}
              <b>Name:</b>{" "}
            </span>
            <p style={{ marginBottom: "15px" }}>
              {productDetails.name ? productDetails.name : "NA"}
            </p>
          </Col>
          <Col span={11}>
            <span>
              {" "}
              <b>Name(Arabic):</b>{" "}
            </span>
            <p style={{ marginBottom: "15px" }}>
              {productDetails.name_ar ? productDetails.name_ar : "NA"}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <span>
              {" "}
              <b>Description:</b>{" "}
            </span>
            <p style={{ marginBottom: "15px" }}>
              {productDetails.description ? productDetails.description : "NA"}
            </p>
          </Col>
          <Col span={11}>
            <span>
              {" "}
              <b>Description (Arabic):</b>{" "}
            </span>
            <p style={{ marginBottom: "15px" }}>
              {productDetails.description_ar
                ? productDetails.description_ar
                : "NA"}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <span>
              {" "}
              <b>Price(in SAR):</b>{" "}
            </span>
            <p style={{ marginBottom: "15px" }}>
              {productDetails.price || productDetails.price == 0
                ? productDetails.price
                : "NA"}
            </p>
          </Col>
          <Col span={11}>
            <span>
              {" "}
              <b>Calories:</b>{" "}
            </span>
            <p style={{ marginBottom: "15px" }}>
              {productDetails.calories ? productDetails.calories : "NA"}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <span>
              {" "}
              <b>Type:</b>{" "}
            </span>
            <p style={{ marginBottom: "15px" }}>
              {productDetails.meal_type ? productDetails.meal_type : "NA"}
            </p>
          </Col>
        </Row>
        <Row>
          {productDetails.types ? (
            <Row>
              {productDetails.types.map((e, index) => (
                <Row style={{ padding: "1rem" }}>
                  <Col span={7}>
                    <span>
                      {" "}
                      <b>Field Title (En)</b>
                    </span>
                    <p>
                      {productDetails.types[index].title
                        ? productDetails.types[index].title
                        : "NA"}
                    </p>
                  </Col>
                  <Col span={7}>
                    <span>
                      {" "}
                      <b>Field Title (Ar)</b>
                    </span>
                    <p>
                      {productDetails.types[index].title_ar
                        ? productDetails.types[index].title_ar
                        : "NA"}
                    </p>
                  </Col>
                  <Col span={7}>
                    <span>
                      {" "}
                      <b>Selection Type</b>
                    </span>
                    <p>
                      {productDetails.types[index]._single_select === true
                        ? "Single Select"
                        : "Multiple Select"}
                    </p>
                  </Col>
                  {productDetails.types[index]._single_select === false ? (
                    <Col span={3}>
                      <span>
                        {" "}
                        <b>Max Items</b>
                      </span>
                      <p>
                        {productDetails.types[index].max_limit
                          ? productDetails.types[index].max_limit
                          : "NA"}
                      </p>
                    </Col>
                  ) : (
                    <Col span={3}>
                      <span>
                        {" "}
                        <b>Max Items</b>
                      </span>
                      <p>1</p>
                    </Col>
                  )}
                  <Row style={{ padding: "1rem" }}>
                    {productDetails.types[index].types &&
                      productDetails.types[index].types.map((x, index1) => (
                        <>
                          <Row>
                            <Col span={4}>
                              <span>
                                {" "}
                                <b>Field Label (En)</b>
                              </span>
                              <p>
                                {productDetails.types[index].types[index1]
                                  .option_title
                                  ? productDetails.types[index].types[index1]
                                      .option_title
                                  : "NA"}
                              </p>
                            </Col>
                            <Col span={4}>
                              <span>
                                {" "}
                                <b>Field Label (Ar)</b>
                              </span>
                              <p>
                                {productDetails.types[index].types[index1]
                                  .option_title_ar
                                  ? productDetails.types[index].types[index1]
                                      .option_title_ar
                                  : "NA"}
                              </p>
                            </Col>
                            <Col span={3}>
                              <span>
                                {" "}
                                <b>Price</b>
                              </span>
                              <p>
                                {productDetails.types[index].types[index1].price
                                  ? productDetails.types[index].types[index1]
                                      .price
                                  : "NA"}
                              </p>
                            </Col>
                            <Col span={3}>
                              <span>
                                {" "}
                                <b>Calories</b>
                              </span>
                              <p>
                                {productDetails.types[index].types[index1]
                                  .cal != "1"
                                  ? productDetails.types[index].types[index1]
                                      .cal
                                  : "NA"}
                              </p>
                            </Col>
                            {productDetails.types[index].types[index1]
                              .sub_types_max_limit == 0 && (
                              <Col span={6}>
                                <span>
                                  {" "}
                                  <b>Selection Type</b>
                                </span>
                                <p>None</p>
                              </Col>
                            )}
                            {productDetails.types[index].types[index1]
                              .sub_types_max_limit == 1 && (
                              <Col span={6}>
                                <span>
                                  {" "}
                                  <b>Selection Type</b>
                                </span>
                                <p>Single Select</p>
                              </Col>
                            )}
                            {productDetails.types[index].types[index1]
                              .sub_types_max_limit > 1 && (
                              <Col span={6}>
                                <span>
                                  {" "}
                                  <b>Selection Type</b>
                                </span>
                                <p>Multiple Select</p>
                              </Col>
                            )}
                          </Row>
                          {productDetails.types[index].types[index1]
                            .sub_types_max_limit != 0 && (
                            <Row style={{ padding: "1rem" }}>
                              <Col span={6}>
                                <span>
                                  {" "}
                                  <b>Max Limit</b>
                                </span>
                                <p>
                                  {productDetails.types[index].types[index1]
                                    .sub_types_max_limit
                                    ? productDetails.types[index].types[index1]
                                        .sub_types_max_limit
                                    : "NA"}
                                </p>
                              </Col>
                              <Col span={6}>
                                <span>
                                  {" "}
                                  <b>Item Label (En)</b>
                                </span>
                                <p>
                                  {productDetails.types[index].types[index1]
                                    .sub_types_option_title
                                    ? productDetails.types[index].types[index1]
                                        .sub_types_option_title
                                    : "NA"}
                                </p>
                              </Col>
                              <Col span={6}>
                                <span>
                                  {" "}
                                  <b>Item Label (Ar)</b>
                                </span>
                                <p>
                                  {productDetails.types[index].types[index1]
                                    .sub_types_option_title_ar
                                    ? productDetails.types[index].types[index1]
                                        .sub_types_option_title_ar
                                    : "NA"}
                                </p>
                              </Col>
                            </Row>
                          )}
                          <Row style={{ padding: "1rem" }}>
                            {productDetails.types[index].types[index1]
                              .subTypes &&
                              productDetails.types[index].types[
                                index1
                              ].subTypes.map((y, index2) => (
                                <Row>
                                  <Col span={6}>
                                    <span>
                                      {" "}
                                      <b>Item Name (En)</b>
                                    </span>
                                    <p>
                                      {productDetails.types[index].types[index1]
                                        .subTypes[index2].product_title
                                        ? productDetails.types[index].types[
                                            index1
                                          ].subTypes[index2].product_title
                                        : "NA"}
                                    </p>
                                  </Col>
                                  <Col span={6}>
                                    <span>
                                      {" "}
                                      <b>Item Name (Ar)</b>
                                    </span>
                                    <p>
                                      {productDetails.types[index].types[index1]
                                        .subTypes[index2].product_title_ar
                                        ? productDetails.types[index].types[
                                            index1
                                          ].subTypes[index2].product_title_ar
                                        : "NA"}
                                    </p>
                                  </Col>
                                  <Col span={6}>
                                    <span>
                                      {" "}
                                      <b>Price</b>
                                    </span>
                                    <p>
                                      {productDetails.types[index].types[index1]
                                        .subTypes[index2].price
                                        ? productDetails.types[index].types[
                                            index1
                                          ].subTypes[index2].price
                                        : "NA"}
                                    </p>
                                  </Col>
                                  <Col span={6}>
                                    <span>
                                      {" "}
                                      <b>Calories</b>
                                    </span>
                                    <p>
                                      {productDetails.types[index].types[index1]
                                        .subTypes[index2].cal != "1"
                                        ? productDetails.types[index].types[
                                            index1
                                          ].subTypes[index2].cal
                                        : "NA"}
                                    </p>
                                  </Col>
                                </Row>
                              ))}
                          </Row>
                        </>
                      ))}
                  </Row>
                </Row>
              ))}
            </Row>
          ) : (
            "NA"
          )}
        </Row>
        <Row>
          <Col span={11}>
            <span>
              {" "}
              <b>Food Categories Name:</b>{" "}
            </span>
            <p style={{ marginBottom: "15px" }}>
              {productDetails.food_categories_english_name
                ? productDetails.food_categories_english_name
                : "NA"}
            </p>
          </Col>
          <Col span={11}>
            <span>
              {" "}
              <b>Food Sub CategoriesList:</b>{" "}
            </span>
            <ul className="pl-12" style={{ marginBottom: "15px" }}>
              {productDetails.food_sub_categories_english_List &&
                productDetails.food_sub_categories_english_List.map((ele) => (
                  <li>{ele}</li>
                ))}
            </ul>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <span>
              {" "}
              <b>Active Status:</b>{" "}
            </span>
            <p style={{ marginBottom: "15px" }}>
              {productDetails.is_active ? "Active" : "InActive"}
            </p>
          </Col>
        </Row>
        <Row></Row>
        <Row></Row>
      </Modal>
    );
  }
}

export default ViewProductModal;
