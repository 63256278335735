import axios from "axios";
import { store } from "../redux/store/store";

const METHOD = {
  GET: "get",
  POST: "post",
};

const BASEURL = process.env.REACT_APP_API_PATH;

class Api {
  isLoggedIn = false;
  userData = {};
  constructor() {
    this.baseURL = BASEURL;
    // this.getAuthenticationInfo();
  }

  // getAuthenticationInfo() {
  //   if (JSON.parse(localStorage.getItem("state")).data.user !== null) {
  //     this.isLoggedIn = true;
  //     this.userData = JSON.parse(localStorage.getItem("state")).data.user;
  //   }
  // }

  get(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.GET, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          // console.log(error);
        });
    });
  }

  post(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.POST, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          // console.log(error);
        });
    });
  }

  api(method, url, data) {
    return new Promise((resolve, reject) => {
      let axiosConfig = {};
      axiosConfig.method = method;
      axiosConfig.url = this.baseURL + url;
      axiosConfig.headers = this.setHeaders(data);
      console.log(axiosConfig.url, axiosConfig.headers);
      if (data) {
        if (data.params) axiosConfig.params = data.params;
        if (data.data) axiosConfig.data = data.data;
      }
      axios(axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve(error);
          //DEFAULT ERROR HANDLING
        });
    });
  }

  setHeaders(data) {
    let headers = {};
    headers["accept-language"] = "en";
    if (data) {
      headers["Content-Type"] = "application/json";

      if (data.headers)
        for (var key in data.headers)
          if (data.headers.hasOwnProperty(key))
            headers[key] = data.headers[key];
    }
    let state = store.getState();
    if (state.data.get("user") != null) {
      headers["authorisation"] = state.data.get("user").jwt_token;
      headers["restaurantId"] = state.data.get("user").id;
    }
    return headers;
  }
}

export default Api;
