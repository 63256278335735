const options = [
  {
    key: "dashboard",
    label: "Restaurant Dashboard",
    leftIcon: "fas fa-tachometer-alt",
    is_admin: false,
  },
  {
    key: "products",
    label: "Products",
    leftIcon: "fas fa-info-circle",
    is_admin: false,
  },
];
export default options;
