import React from "react";
import "../../src/index.css";

class FoodcourtLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="ex_spin">
        <div className="spin_div">
          <img src="https://openxcell-development-public.s3.ap-south-1.amazonaws.com/food_court/FoodCourtLoader.gif" />
        </div>
      </div>
    );
  }
}

export default FoodcourtLoader;
