import React from 'react';
import { Provider } from "react-redux";
import AppRouter from './routers/appRouter';
import { history, store } from './redux/store/store';

const App = () => (
  <Provider store={store}>
    <AppRouter history={history}/>
  </Provider>
)

export default App;
