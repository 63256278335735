import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Modal,
  Input,
  notification,
  Spin,
  Button,
  Upload,
  Icon,
  Row,
  Col,
} from "antd";
import SimpleReactValidator from "simple-react-validator";
import { Select } from "antd";
import clone from "clone";
import { withRouter } from "react-router-dom";
import { globalVariables } from "../../Globals";
import Api from "../../helper/apiHandler";

const api = new Api();
const dummyImage = process.env.REACT_APP_S3_DUMMY_IMAGE_PATH;

const { TextArea } = Input;
// select zone
const { Option } = Select;
let { API_URL } = globalVariables;
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
class EditProductModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selctedMealType: null,
      allSubCategories: [],
      allCategories: [],

      selctedSubCategory: this.props.fields["food_sub_categoriesList"],
      selctedCategory: this.props.fields["food_categories_id"],
      defaultSelectedSubCategory: null,
      defaultSelectedCategory: null,
      showEditProductModal: this.props.showEditProductModal,
      loader: false,
      fields: clone(this.props.fields),

      errMsg: false,
      errMessage: "",
      errType: "Success",
      previewVisible: false,
      previewImage: "",
      fileList: this.props.fields["imagesArray"].map((img, i) => {
        return {
          uid: i,
          name: `image_${i}`,
          status: "done",
          url: img,
          size: 10 * 1024 * 1024,
        };
      }),
      showError: false,
      isDisabled: false,
      imageUrl: this.props.fields["featured_image"],
      imgErrMsg: null,
      imageArray: [],
    };
    this.validator = new SimpleReactValidator();
  }
  componentDidMount = () => {
    this.setState(
      {
        userdata: this.props.user,
      },
      () => {
        this._getAllSubCategories();
        this._getAllCategories();
        this.checkSelectType();
      }
    );
  };
  checkSelectType = () => {
    const { fields } = this.state;
    fields["types"].map((e, index) => {
      fields["types"][index]["types"].map((x, index1) => {
        if (fields["types"][index]["types"][index1]["select_type"] == 0) {
          let stateField = { ...fields };
          stateField["types"][index]["types"][index1]["subTypes"].push({
            cal: 0.0,
            product_title: null,
            product_title_ar: null,
            price: null,
          });
          this.setState({
            fields: { ...stateField },
          });
        }
      });
    });
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps !== this.props) {
      let fileListArray = [];

      if (nextProps.fields.imagesArray) {
        nextProps.fields.imagesArray.forEach((element, index) => {
          fileListArray.push({
            uid: index,
            url: element,
          });
        });
      }
      this.setState(
        {
          showEditProductModal: nextProps.showEditProductModal,
          fields: nextProps.fields,
          fileList: fileListArray,
          selctedMealType: nextProps.fields["meal_type"],
          selctedCategory: nextProps.fields["food_categories_id"],
          selctedSubCategory: nextProps.fields["food_sub_categoriesList"],
        },
        () => {
          this.setState({
            imageUrl: this.props.fields["featured_image"],
            defaultSelectedCategory:
              this.props.fields["food_categories_english_name"],
            defaultSelectedSubCategory:
              this.props.fields["food_sub_categoriesList"],
          });
        }
      );
    }
  };

  _getAllCategories = () => {
    const { searchUser } = this.state;
    let _this = this;

    let values = {
      name: searchUser,
    };
    _this.setState({ loader: true });
    api
      .post("/rest/all-food-categories-list", {
        data: values,
      })
      .then(function (res) {
        if (res.status === 200) {
          _this.setState({
            allCategories: res.data,
            loader: false,
          });
        } else if (res.status === 401) {
          _this.openNotificationWithIcon("error", "Error", res.message);
          _this.props.history.push("/");
          _this.props.logout();
          _this.setState({ loader: false });
        } else {
          _this.openNotificationWithIcon("error", "Error", res.message);
          _this.setState({ loader: false });
        }
      })
      .catch((err) => {
        _this.setState({
          errMsg: true,
          errMessage: "Something went wrong!!",
          searchUser: "",
          errType: "error",
          loader: false,
        });
      });
  };
  _getAllSubCategories = () => {
    let _this = this;
    _this.setState({ loader: true });
    let values = {
      search: "",
    };
    api
      .post("/rest/all-sub-food-categories-list", {
        data: values,
      })
      .then(function (res) {
        if (res.status === 200) {
          _this.setState({
            allSubCategories: res.data,
            loader: false,
          });
        } else if (res.status === 401) {
          _this.openNotificationWithIcon("error", "Error", res.message);
          _this.props.history.push("/");
          _this.props.logout();
          _this.setState({ loader: false });
        } else {
          _this.openNotificationWithIcon("error", "Error", res.message);
          _this.setState({ loader: false });
        }
      })
      .catch(() => {
        _this.setState({
          errMsg: true,
          errMessage: "Something went wrong!!",
          searchUser: "",
          errType: "error",
          loader: false,
        });
      });
  };
  onChangeMealType = (value) => {
    // console.log(`selected ${value}`);
    //console.log("this is meal", value)
    this.setState({ selctedMealType: value });
  };

  onBlurMealType = () => {
    // console.log('blur');
  };

  onFocusMealType = () => {
    // console.log('focus');
  };

  handleCancel = () => this.setState({ previewVisible: false });

  onSearchMealType = (val) => {
    // console.log('search:', val);
  };
  // select sub category
  onChange = (value) => {
    this.setState({ selctedSubCategory: value });
  };

  onBlur = () => {
    // console.log('blur');
  };

  onFocus = () => {
    // console.log('focus');
  };

  onSearch = (val) => {
    // console.log('search:', val);
  };
  // select zone

  // select Category
  onChangeCategory = (value) => {
    // console.log(`selected ${value}`);
    this.setState({ selctedCategory: value });
  };

  onBlurCategory = () => {
    // console.log('blur');
  };

  onFocusCategory = () => {
    // console.log('focus');
  };

  onSearchCategory = (val) => {
    // console.log('search:', val);
  };
  openNotificationWithIconError = (type) => {
    notification[type]({
      message: this.state.errType,
      description: this.state.errMessage,
    });
    this.setState({ errMsg: false });
  };

  _handleChange = (field, e) => {
    let fields = this.state.fields;
    if (e.target.value.trim() == "") {
      fields[field] = "";
    } else {
      fields[field] = e.target.value;
    }
    this.setState({ fields });
  };

  _resetForm = () => {
    const { fields } = this.state;

    this.setState({ fields, showError: false, fileList: [], imagesArray: [] });
  };

  _closeEditProductModal = () => {
    this.setState({ showEditProductModal: false, imageUrl: null });
    this.props.closeEditProductModal();
    this._resetForm();
  };

  _editProduct = () => {
    const { token, getAllProducts } = this.props;
    const { fields } = this.state;
    if (this.validator.allValid() && !this.state.showError) {
      this.setState({ loader: true, isDisabled: true });

      let formData = new FormData();
      let x;
      if (fields["meal_type"]) {
        x = fields["meal_type"];
      } else {
        x = "";
      }
      let jsonString = {
        name: fields["name"],
        name_ar: fields["name_ar"],
        price: fields["price"],
        calories: fields["calories"],
        description: fields["description"],
        description_ar: fields["description_ar"],
        meal_type: x,
        food_categories_id: this.state.selctedCategory.toString(),
        food_sub_categories_id: this.state.selctedSubCategory,
        restaurant_id: this.state.userdata.id,
      };
      let data = fields["types"][0]["title"] ? fields["types"] : [];
      if (this.refs.uploadImg.files[0]) {
        formData.append("featured_image", this.refs.uploadImg.files[0]);
      }
      let imagesArray = [];
      this.state.fileList.forEach((element) => {
        if (element.originFileObj) {
          formData.append("image", element.originFileObj);
        } else {
          imagesArray.push(element.url);
        }
      });
      formData.append("jsonstring", JSON.stringify(jsonString));
      formData.append("types", JSON.stringify(data));
      formData.append("imagesArray", imagesArray);
      formData.append("id", fields["id"]);

      let _this = this;
      _this.setState({ loader: true });
      api
        .post("/rest/edit-product", {
          data: formData,
        })
        .then(function (res) {
          if (res.status === 200) {
            _this.setState({
              errMsg: true,
              errMessage: res.message,
              loader: false,
              errType: "Success",
              showError: false,
              isDisabled: false,
            });
          } else if (res.status === 401) {
            _this.openNotificationWithIcon("error", "Error", res.message);
            _this.props.history.push("/");
            _this.props.logout();
            _this.setState({ loader: false });
          } else {
            _this.openNotificationWithIcon("error", "Error", res.message);
            _this.setState({ loader: false });
          }
          _this._closeEditProductModal();
          getAllProducts();
          _this._resetForm();
        })
        .catch(() => {
          _this.setState({
            errMsg: true,
            errMessage: "Something went wrong!!",
            searchUser: "",
            errType: "error",
            loader: false,
          });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  onChangeFieldData = (
    value,
    field,
    index = null,
    subtype,
    index1 = null,
    subdata,
    index2 = null,
    language
  ) => {
    // this.validator.purgeFields();

    let stateField = { ...this.state.fields };
    if (language) {
      stateField[field][index][subtype][index1][subdata][index2][language] =
        value;
    } else if (!language && (index2 || index2 == 0)) {
      stateField[field][index][subtype][index1][subdata][index2] = value;
    } else {
      stateField[field][index][subtype][index1][subdata] = value;
    }
    this.setState({ fields: { ...stateField } });
  };

  onChangeField = (
    value,
    field,
    index = null,
    subtype,
    index1 = null,
    language
  ) => {
    // this.validator.purgeFields();

    let stateField = { ...this.state.fields };
    if (
      subtype === "_single_select" &&
      value == "true" &&
      !language &&
      index1 == null &&
      stateField[field][index][subtype] != null
    ) {
      stateField["types"][index]["max_limit"] = "1";
    }
    if (
      subtype === "_single_select" &&
      value == "false" &&
      !language &&
      index1 == null &&
      stateField[field][index][subtype] != null
    ) {
      stateField["types"][index]["types"].map((e, index1) => {
        stateField["types"][index]["types"][index1]["select_type"] = 0;
        stateField["types"][index]["types"][index1]["sub_types_max_limit"] =
          "0";
        stateField["types"][index]["types"][index1]["sub_types_option_title"] =
          "";
        stateField["types"][index]["types"][index1][
          "sub_types_option_title_ar"
        ] = "";
        stateField["types"][index]["types"][index1]["subTypes"].map((e) => {
          e["price"] = null;
          e["cal"] = 0.0;
          e["product_title"] = null;
          e["product_title_ar"] = null;
        });
      });
    }
    if (
      language === "select_type" &&
      stateField["types"][index]["types"][index1]["subTypes"] != null &&
      stateField["types"][index]["types"][index1]["sub_types_max_limit"] !=
        null &&
      value == 1
    ) {
      stateField["types"][index]["types"][index1]["sub_types_max_limit"] = "1";
    }
    if (
      language === "select_type" &&
      stateField["types"][index]["types"][index1]["subTypes"] != null &&
      stateField["types"][index]["types"][index1]["sub_types_max_limit"] !=
        null &&
      value == 0
    ) {
      stateField["types"][index]["types"][index1]["sub_types_max_limit"] = "0";
      stateField["types"][index]["types"][index1]["sub_types_option_title"] =
        "";
      stateField["types"][index]["types"][index1]["sub_types_option_title_ar"] =
        "";
      stateField["types"][index]["types"][index1]["subTypes"].map((e) => {
        e["price"] = null;
        e["cal"] = 0.0;
        e["product_title"] = null;
        e["product_title_ar"] = null;
      });
    }
    if (language) {
      stateField[field][index][subtype][index1][language] = value;
    } else if (!language && (index1 || index1 == 0)) {
      stateField[field][index][subtype][index1] = value;
    } else {
      stateField[field][index][subtype] = value;
    }
    this.setState({ fields: { ...stateField } });
  };

  _onChange = (e) => {
    const file = this.refs.uploadImg.files[0];
    if (file) {
      let size = file.size / 1024 / 1024;
      let type = file.type;
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        if (size <= 10) {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.setState({
              imageUrl: reader.result,
            });
          };
          if (file) {
            reader.readAsDataURL(file);
            this.setState({
              imageUrl: reader.result,
              imgErrMsg: "",
              showError: false,
            });
          } else {
            // this.setState({
            //   imageUrl: PUBLIC_URL + "images/Product-dummy.png",
            //   imgErrMsg: "",
            //   showError: false,
            // });
          }
        } else {
          // this.setState({
          //   imageUrl: PUBLIC_URL + "images/Product-dummy.png",
          //   imgErrMsg: "Please select image of less than 10MB in size.",
          //   showError: true,
          // });
        }
      } else {
        // this.setState({
        //   imageUrl: PUBLIC_URL + "images/Product-dummy.png",
        //   imgErrMsg: "Please select image of .png, .jpg, .jpeg type.",
        //   showError: true,
        // });
      }
    }
  };
  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = ({ file, fileList }) => {
    //console.log("file object", file);
    if (file.size <= 10 * 1024 * 1024) {
      this.setState({
        fileList,
        imgFetureErrMsg: "",
        showImageError: false,
      });
    } else {
      this.setState({
        imgFetureErrMsg: "Please select image of less than 10MB in size.",
        showImageError: true,
      });
    }
  };
  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const {
      loader,
      showEditProductModal,
      fields,

      errMsg,
      errType,
      showError,
      isDisabled,
      showErr,
      showImageError,
      imageUrl,
      defaultSelectedCategory,
      defaultSelectedSubCategory,
    } = this.state;

    if (errMsg) {
      this.openNotificationWithIconError(errType.toLowerCase());
    }

    return (
      <div>
        <Modal
          maskClosable={false}
          width="1400px"
          title="Edit Product"
          visible={showEditProductModal}
          onCancel={this._closeEditProductModal}
          confirmLoading={loader}
          footer={[
            <Button onClick={this._closeEditProductModal}>Cancel</Button>,
            <Button disabled={isDisabled} onClick={this._editProduct}>
              Update
            </Button>,
          ]}
        >
          <div style={{ marginBottom: "15px" }}>
            <span>Food Image:</span>
            <br />
            <img src={imageUrl} style={{ width: "120px", height: "120px" }} />
          </div>
          <div style={{ marginBottom: "15px" }}>
            <form onSubmit={this.onSubmit}>
              <input
                ref="uploadImg"
                type="file"
                name="selectedFile"
                onChange={this._onChange}
                accept="image/x-png,image/jpg,image/jpeg"
              />
            </form>

            <span className="image-note">
              Supported format : .jpg , .png , .jpeg.
            </span>
          </div>
          {showError && (
            <span style={{ color: "red" }}>{this.state.imgErrMsg}</span>
          )}
          {/* upload other images */}
          <div className="clearfix">
            <Upload
              customRequest={this.dummyRequest}
              listType="picture-card"
              fileList={fileList}
              onPreview={this.handlePreview}
              onChange={this.handleChange}
            >
              {fileList && fileList.length >= 5 ? null : uploadButton}
            </Upload>
            <Modal
              maskClosable={false}
              visible={previewVisible}
              footer={null}
              onCancel={this.handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
            {showImageError && (
              <div>
                <span style={{ color: "red" }}>
                  {this.state.imgFetureErrMsg}
                </span>
              </div>
            )}
          </div>

          <Row>
            <Col
              span={11}
              style={{ marginBottom: "15px", marginRight: "15px" }}
            >
              <span>Name: *</span>
              <Input
                placeholder="Name"
                onChange={this._handleChange.bind(this, "name")}
                value={fields["name"]}
              />
              <span style={{ color: "red" }}>
                {this.validator.message(
                  "Name",
                  fields["name"],
                  "required|max:250"
                )}
              </span>
            </Col>
            <Col span={11} style={{ marginBottom: "15px" }}>
              <span>Name(Arabic): *</span>
              <Input
                placeholder="Name(arabic)"
                onChange={this._handleChange.bind(this, "name_ar")}
                value={fields["name_ar"]}
              />
              <span style={{ color: "red" }}>
                {this.validator.message(
                  "Name(arabic)",
                  fields["name_ar"],
                  "required"
                )}
              </span>
            </Col>
          </Row>

          <Row>
            <Col
              span={11}
              style={{ marginBottom: "15px", marginRight: "15px" }}
            >
              <span>Description: </span>
              <TextArea
                placeholder="Description"
                onChange={this._handleChange.bind(this, "description")}
                value={fields["description"]}
              />
            </Col>
            <Col span={11} style={{ marginBottom: "15px" }}>
              <span>Description(Ar): </span>
              <TextArea
                placeholder="Description(arabic)"
                onChange={this._handleChange.bind(this, "description_ar")}
                value={fields["description_ar"]}
              />
            </Col>
          </Row>

          <Row>
            <Col
              span={11}
              style={{ marginBottom: "15px", marginRight: "15px" }}
            >
              <span>Price(in SAR): *</span>
              <Input
                placeholder="Price"
                onChange={this._handleChange.bind(this, "price")}
                value={fields["price"]}
              />
              <span style={{ color: "red" }}>
                {this.validator.message(
                  "Price",
                  fields["price"],
                  "required|numeric"
                )}
              </span>
            </Col>
            <Col span={11} style={{ marginBottom: "15px" }}>
              <span>Calories: </span>
              <Input
                placeholder="Calories"
                onChange={this._handleChange.bind(this, "calories")}
                value={fields["calories"]}
              />
            </Col>
          </Row>

          {/* <Row>
            <Col span={11} style={{ marginBottom: "15px" }}>
              <span>Type: </span>
              <Input
                placeholder="Type"
                onChange={this._handleChange.bind(this, "meal_type")}
                value={fields["meal_type"] ? fields["meal_type"] : null}
              />
            </Col>
          </Row> */}

          <div>
            <Row gutter={16} className="main-row dynamic_fields-row">
              <div className="dynamic_fields-main">
                {fields["types"] &&
                  fields["types"].map((el, index) => (
                    <div key={index}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                          width: "100%",
                          marginBottom: "30px",
                        }}
                      >
                        <Col
                          className="d-flex"
                          span={6}
                          style={{ marginLeft: "7px" }}
                        >
                          <div className="mr-fast-15">
                            <span>Field Label(En): *</span>
                            <Input
                              placeholder="Field Label(in English)"
                              onChange={(e) => {
                                this.onChangeField(
                                  e.target.value,
                                  "types",
                                  index,
                                  "title"
                                );
                              }}
                              value={fields["types"][index]["title"]}
                            />
                            <span style={{ color: "red" }}>
                              {this.validator.message(
                                "Field Label(in English)",
                                fields["types"].length > 1
                                  ? fields["types"][index]["title"]
                                  : [...Array(51)].join(),
                                "required|max:50"
                              )}
                            </span>
                          </div>
                        </Col>
                        <Col
                          className="d-flex"
                          span={5}
                          style={{ marginLeft: "7px" }}
                        >
                          <div className="mr-fast-15">
                            <span>Field Label(Ar): *</span>
                            <Input
                              placeholder="Field Label(in Arabic)"
                              onChange={(e) => {
                                this.onChangeField(
                                  e.target.value,
                                  "types",
                                  index,
                                  "title_ar"
                                );
                              }}
                              value={fields["types"][index]["title_ar"]}
                            />
                            <span style={{ color: "red" }}>
                              {this.validator.message(
                                "Field Label(in Arabic)",
                                fields["types"].length > 1
                                  ? fields["types"][index]["title_ar"]
                                  : [...Array(51)].join(),
                                "required|max:50"
                              )}
                            </span>
                          </div>
                        </Col>
                        <Col className="d-flex" span={6}>
                          <div className="mr-fast-15">
                            <span style={{ display: "block" }}>
                              Field Label Selection: *
                            </span>
                            <Select
                              showSearch
                              style={{
                                width: "100%",
                                marginRight: "15px",
                              }}
                              placeholder="Select Type"
                              onChange={(e) => {
                                this.onChangeField(
                                  e,
                                  "types",
                                  index,
                                  "_single_select"
                                );
                              }}
                              value={
                                fields["types"][index]["_single_select"] != null
                                  ? fields["types"][index][
                                      "_single_select"
                                    ].toString()
                                  : null
                              }
                            >
                              <Option value={"true"}>Single Select</Option>
                              <Option value={"false"}>Multiple Select</Option>
                              <Option value={null}>None</Option>
                            </Select>
                            <span style={{ color: "red" }}>
                              {this.validator.message(
                                "Selection Type",
                                fields["types"].length > 1
                                  ? fields["types"][index]["_single_select"]
                                  : true,
                                "required"
                              )}
                            </span>
                          </div>
                        </Col>
                        {fields["types"][index]["_single_select"].toString() ==
                          "false" && (
                          <Col
                            className="d-flex"
                            span={2}
                            style={{ marginRight: "7px" }}
                          >
                            <div className="mr-fast-15">
                              <span>Max Items:*</span>
                              <Input
                                placeholder="Max Items"
                                onChange={(e) => {
                                  this.onChangeField(
                                    e.target.value,
                                    "types",
                                    index,
                                    "max_limit"
                                  );
                                }}
                                value={fields["types"][index]["max_limit"]}
                              />
                              <span style={{ color: "red" }}>
                                {this.validator.message(
                                  "Max Items",
                                  fields["types"][index]["max_limit"],
                                  "required|numeric"
                                )}
                              </span>
                            </div>
                          </Col>
                        )}
                        <Col span={2}>
                          <div className="add-remove-btn first">
                            <Button
                              style={{ maxWidth: "fit-content" }}
                              className="footer-mr-15"
                              type="primary"
                              disabled={this.state.disabled}
                              onClick={() => {
                                let stateField = { ...this.state.fields };

                                stateField["types"].push({
                                  title_type: 1,
                                  title: null,
                                  title_ar: null,
                                  _single_select: "true",
                                  max_limit: "1",
                                  types: [
                                    {
                                      price: null,
                                      cal: 0.0,
                                      select_type: 0,
                                      option_title: null,
                                      option_title_ar: null,
                                      sub_types_max_limit: "0",
                                      sub_types_option_title: null,
                                      sub_types_option_title_ar: null,
                                      subTypes: [
                                        {
                                          cal: 0.0,
                                          product_title: null,
                                          product_title_ar: null,
                                          price: null,
                                        },
                                      ],
                                    },
                                  ],
                                });
                                this.setState({
                                  fields: { ...stateField },
                                });
                              }}
                            >
                              Add Field
                            </Button>
                          </div>
                        </Col>

                        {/* minus each item */}
                        <Col span={2}>
                          <Button
                            style={{ height: "33px" }}
                            className="footer-mr-15"
                            type="primary"
                            disabled={this.state.disabled}
                            onClick={() => {
                              let stateField = { ...this.state.fields };

                              // one element will always there
                              if (stateField["types"].length != 1) {
                                // console.log(
                                //   "^^^^^pre",
                                //   index,
                                //   stateField["types"]
                                // );
                                stateField["types"].splice(index, 1);
                                // console.log(
                                //   "^^^^^prod",
                                //   stateField["types"]
                                // );

                                // stateField["types"].pop();
                                this.setState({
                                  fields: { ...stateField },
                                });
                              }
                            }}
                          >
                            -
                          </Button>
                        </Col>
                      </Row>
                      {fields["types"][index]["_single_select"].toString() && (
                        <Row style={{ padding: "3rem", paddingTop: "1rem" }}>
                          {fields["types"][index]["types"].map((a, index1) => (
                            <>
                              <Row
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  marginTop: "15px",
                                  marginLeft: "7px",
                                }}
                              >
                                {fields["types"][index][
                                  "_single_select"
                                ].toString() == "false" && (
                                  <Col className="d-flex" span={4}>
                                    <div className="mr-fast-15">
                                      <span style={{ display: "block" }}>
                                        Field Value Selection: *
                                      </span>
                                      <Select
                                        showSearch
                                        style={{
                                          width: "100%",
                                          marginRight: "15px",
                                        }}
                                        disabled="true"
                                        placeholder="Select Type"
                                        onChange={(e) => {
                                          this.onChangeField(
                                            e,
                                            "types",
                                            index,
                                            "types",
                                            index1,
                                            "select_type"
                                          );
                                        }}
                                        value={
                                          fields["types"][index]["types"][
                                            index1
                                          ]["select_type"]
                                        }
                                      >
                                        <Option value={1}>Single Select</Option>
                                        <Option value={2}>
                                          Multiple Select
                                        </Option>
                                        <Option value={0}>None</Option>
                                      </Select>
                                      <span style={{ color: "red" }}>
                                        {this.validator.message(
                                          "Selection Type",
                                          fields["types"][index]["types"][
                                            index1
                                          ]["select_type"],
                                          "required"
                                        )}
                                      </span>
                                    </div>
                                  </Col>
                                )}
                                {fields["types"][index][
                                  "_single_select"
                                ].toString() == "true" && (
                                  <Col className="d-flex" span={4}>
                                    <div className="mr-fast-15">
                                      <span style={{ display: "block" }}>
                                        Field Value Selection: *
                                      </span>
                                      <Select
                                        showSearch
                                        style={{
                                          width: "100%",
                                          marginRight: "15px",
                                        }}
                                        placeholder="Select Type"
                                        onChange={(e) => {
                                          this.onChangeField(
                                            e,
                                            "types",
                                            index,
                                            "types",
                                            index1,
                                            "select_type"
                                          );
                                        }}
                                        value={
                                          fields["types"][index]["types"][
                                            index1
                                          ]["select_type"]
                                        }
                                      >
                                        <Option value={1}>Single Select</Option>
                                        <Option value={2}>
                                          Multiple Select
                                        </Option>
                                        <Option value={0}>None</Option>
                                      </Select>
                                      <span style={{ color: "red" }}>
                                        {this.validator.message(
                                          "Selection Type",
                                          fields["types"][index]["types"][
                                            index1
                                          ]["select_type"],
                                          "required"
                                        )}
                                      </span>
                                    </div>
                                  </Col>
                                )}
                                <Col
                                  className="d-flex"
                                  span={4}
                                  style={{ marginLeft: "7px" }}
                                >
                                  <div className="mr-fast-15">
                                    <span>Field Value(En):*</span>
                                    <Input
                                      placeholder="Field Value(in English)"
                                      onChange={(e) => {
                                        this.onChangeField(
                                          e.target.value,
                                          "types",
                                          index,
                                          "types",
                                          index1,
                                          "option_title"
                                        );
                                      }}
                                      value={
                                        fields["types"][index]["types"][index1][
                                          "option_title"
                                        ]
                                      }
                                    />
                                    <span style={{ color: "red" }}>
                                      {this.validator.message(
                                        "Field Value(in English)",
                                        fields["types"][index]["types"][index1][
                                          "option_title"
                                        ],
                                        "required"
                                      )}
                                    </span>
                                  </div>
                                </Col>
                                <Col
                                  className="d-flex"
                                  span={4}
                                  style={{ marginLeft: "7px" }}
                                >
                                  <div className="mr-fast-15">
                                    <span>Field Value(Ar):*</span>
                                    <Input
                                      placeholder="Field Value(in Arabic)"
                                      onChange={(e) => {
                                        this.onChangeField(
                                          e.target.value,
                                          "types",
                                          index,
                                          "types",
                                          index1,
                                          "option_title_ar"
                                        );
                                      }}
                                      value={
                                        fields["types"][index]["types"][index1][
                                          "option_title_ar"
                                        ]
                                      }
                                    />
                                    <span style={{ color: "red" }}>
                                      {this.validator.message(
                                        "Field Value(in English)",
                                        fields["types"][index]["types"][index1][
                                          "option_title_ar"
                                        ],
                                        "required"
                                      )}
                                    </span>
                                  </div>
                                </Col>
                                <Col
                                  className="d-flex"
                                  span={4}
                                  style={{ marginLeft: "7px" }}
                                >
                                  <div className="mr-fast-15">
                                    <span>Field Price:*</span>
                                    <Input
                                      placeholder="Field Price"
                                      onChange={(e) => {
                                        this.onChangeField(
                                          e.target.value,
                                          "types",
                                          index,
                                          "types",
                                          index1,
                                          "price"
                                        );
                                      }}
                                      value={
                                        fields["types"][index]["types"][index1][
                                          "price"
                                        ]
                                      }
                                    />
                                    <span style={{ color: "red" }}>
                                      {this.validator.message(
                                        "Field Price",
                                        fields["types"][index]["types"][index1][
                                          "price"
                                        ],
                                        "required|numeric"
                                      )}
                                    </span>
                                  </div>
                                </Col>
                                <Col
                                  className="d-flex"
                                  span={4}
                                  style={{ marginLeft: "7px" }}
                                >
                                  <div className="mr-fast-15">
                                    <span>Field Calories:</span>
                                    <Input
                                      placeholder="Field Calories"
                                      onChange={(e) => {
                                        this.onChangeField(
                                          e.target.value,
                                          "types",
                                          index,
                                          "types",
                                          index1,
                                          "cal"
                                        );
                                      }}
                                      value={
                                        fields["types"][index]["types"][index1][
                                          "cal"
                                        ]
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col
                                  span={1}
                                  className="add-remove-btn last"
                                  style={{
                                    marginTop: "20px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Button
                                    className="footer-mr-15"
                                    type="primary"
                                    disabled={this.state.disabled}
                                    onClick={() => {
                                      let stateField = { ...fields };

                                      stateField["types"][index]["types"].push({
                                        max_limit: "1",
                                        cal: 0.0,
                                        price: null,
                                        select_type: 0,
                                        option_title: null,
                                        option_title_ar: null,
                                        sub_types_max_limit: "0",
                                        sub_types_option_title: null,
                                        sub_types_option_title_ar: null,
                                        subTypes: [
                                          {
                                            cal: 0.0,
                                            product_title: null,
                                            product_title_ar: null,
                                            price: null,
                                          },
                                        ],
                                      });
                                      this.setState({
                                        fields: { ...stateField },
                                      });
                                    }}
                                  >
                                    +
                                  </Button>
                                </Col>
                                <Col
                                  span={1}
                                  style={{
                                    marginTop: "20px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Button
                                    className="footer-mr-15"
                                    type="primary"
                                    disabled={this.state.disabled}
                                    onClick={() => {
                                      let stateField = { ...fields };
                                      if (
                                        stateField["types"][index]["types"]
                                          .length > 1
                                      ) {
                                        stateField["types"][index][
                                          "types"
                                        ].splice(index1, 1);
                                        this.setState({
                                          fields: { ...stateField },
                                        });
                                      }
                                    }}
                                  >
                                    -
                                  </Button>
                                </Col>
                              </Row>
                              <Row>
                                {fields["types"][index]["types"][index1][
                                  "select_type"
                                ] == 1 && (
                                  <Row style={{ padding: "1rem" }}>
                                    <>
                                      <Row style={{ padding: "1rem" }}>
                                        <Row style={{ padding: "1rem" }}>
                                          {fields["types"][index]["types"][
                                            index1
                                          ]["select_type"] == 2 && (
                                            <Col
                                              className="d-flex"
                                              span={6}
                                              style={{ marginLeft: "7px" }}
                                            >
                                              <div className="mr-fast-15">
                                                <span>Max Selection: *</span>
                                                <Input
                                                  placeholder="Max Number Of Selection"
                                                  onChange={(e) => {
                                                    this.onChangeField(
                                                      e.target.value,
                                                      "types",
                                                      index,
                                                      "types",
                                                      index1,
                                                      "sub_types_max_limit"
                                                    );
                                                  }}
                                                  value={
                                                    fields["types"][index][
                                                      "types"
                                                    ][index1][
                                                      "sub_types_max_limit"
                                                    ]
                                                  }
                                                />
                                                <span style={{ color: "red" }}>
                                                  {this.validator.message(
                                                    "Max Number Of Selection",
                                                    fields["types"][index][
                                                      "types"
                                                    ][index1][
                                                      "sub_types_max_limit"
                                                    ],
                                                    "required|numeric"
                                                  )}
                                                </span>
                                              </div>
                                            </Col>
                                          )}
                                          <Col
                                            className="d-flex"
                                            span={6}
                                            style={{ marginLeft: "7px" }}
                                          >
                                            <div className="mr-fast-15">
                                              <span>Item Label(En): *</span>
                                              <Input
                                                placeholder="Item Label(in English)"
                                                onChange={(e) => {
                                                  this.onChangeField(
                                                    e.target.value,
                                                    "types",
                                                    index,
                                                    "types",
                                                    index1,
                                                    "sub_types_option_title"
                                                  );
                                                }}
                                                value={
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1][
                                                    "sub_types_option_title"
                                                  ]
                                                }
                                              />
                                              <span style={{ color: "red" }}>
                                                {this.validator.message(
                                                  "Item Label(in English)",
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1][
                                                    "sub_types_option_title"
                                                  ],
                                                  "required"
                                                )}
                                              </span>
                                            </div>
                                          </Col>
                                          <Col
                                            className="d-flex"
                                            span={6}
                                            style={{ marginLeft: "7px" }}
                                          >
                                            <div className="mr-fast-15">
                                              <span>Item Label(Ar): *</span>
                                              <Input
                                                placeholder="Item Label(in Arabic)"
                                                onChange={(e) => {
                                                  this.onChangeField(
                                                    e.target.value,
                                                    "types",
                                                    index,
                                                    "types",
                                                    index1,
                                                    "sub_types_option_title_ar"
                                                  );
                                                }}
                                                value={
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1][
                                                    "sub_types_option_title_ar"
                                                  ]
                                                }
                                              />
                                              <span style={{ color: "red" }}>
                                                {this.validator.message(
                                                  "Item Label(in Arabic)",
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1][
                                                    "sub_types_option_title_ar"
                                                  ],
                                                  "required"
                                                )}
                                              </span>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Row>
                                    </>
                                  </Row>
                                )}
                              </Row>
                              <Row>
                                {fields["types"][index]["types"][index1][
                                  "select_type"
                                ] == 2 && (
                                  <Row style={{ padding: "1rem" }}>
                                    <>
                                      <Row style={{ padding: "1rem" }}>
                                        <Row style={{ padding: "1rem" }}>
                                          {fields["types"][index]["types"][
                                            index1
                                          ]["select_type"] == 2 && (
                                            <Col
                                              className="d-flex"
                                              span={6}
                                              style={{ marginLeft: "7px" }}
                                            >
                                              <div className="mr-fast-15">
                                                <span>Max Selection: *</span>
                                                <Input
                                                  placeholder="Max Number Of Selection"
                                                  onChange={(e) => {
                                                    this.onChangeField(
                                                      e.target.value,
                                                      "types",
                                                      index,
                                                      "types",
                                                      index1,
                                                      "sub_types_max_limit"
                                                    );
                                                  }}
                                                  value={
                                                    fields["types"][index][
                                                      "types"
                                                    ][index1][
                                                      "sub_types_max_limit"
                                                    ]
                                                  }
                                                />
                                                <span style={{ color: "red" }}>
                                                  {this.validator.message(
                                                    "Max Number Of Selection",
                                                    fields["types"][index][
                                                      "types"
                                                    ][index1][
                                                      "sub_types_max_limit"
                                                    ],
                                                    "required|numeric"
                                                  )}
                                                </span>
                                              </div>
                                            </Col>
                                          )}
                                          <Col
                                            className="d-flex"
                                            span={6}
                                            style={{ marginLeft: "7px" }}
                                          >
                                            <div className="mr-fast-15">
                                              <span>Item Label(En): *</span>
                                              <Input
                                                placeholder="Item Label(in English)"
                                                onChange={(e) => {
                                                  this.onChangeField(
                                                    e.target.value,
                                                    "types",
                                                    index,
                                                    "types",
                                                    index1,
                                                    "sub_types_option_title"
                                                  );
                                                }}
                                                value={
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1][
                                                    "sub_types_option_title"
                                                  ]
                                                }
                                              />
                                              <span style={{ color: "red" }}>
                                                {this.validator.message(
                                                  "Item Label(in English)",
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1][
                                                    "sub_types_option_title"
                                                  ],
                                                  "required"
                                                )}
                                              </span>
                                            </div>
                                          </Col>
                                          <Col
                                            className="d-flex"
                                            span={6}
                                            style={{ marginLeft: "7px" }}
                                          >
                                            <div className="mr-fast-15">
                                              <span>Item Label(Ar): *</span>
                                              <Input
                                                placeholder="Item Label(in Arabic)"
                                                onChange={(e) => {
                                                  this.onChangeField(
                                                    e.target.value,
                                                    "types",
                                                    index,
                                                    "types",
                                                    index1,
                                                    "sub_types_option_title_ar"
                                                  );
                                                }}
                                                value={
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1][
                                                    "sub_types_option_title_ar"
                                                  ]
                                                }
                                              />
                                              <span style={{ color: "red" }}>
                                                {this.validator.message(
                                                  "Item Label(in Arabic)",
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1][
                                                    "sub_types_option_title_ar"
                                                  ],
                                                  "required"
                                                )}
                                              </span>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Row>
                                    </>
                                  </Row>
                                )}
                              </Row>
                              <Row>
                                {fields["types"][index]["types"][index1][
                                  "select_type"
                                ] == 1 && (
                                  <Row style={{ padding: "1rem" }}>
                                    {fields["types"][index]["types"][index1][
                                      "subTypes"
                                    ].map((b, index2) => (
                                      <>
                                        <Row
                                          style={{
                                            marginBottom: "20px",
                                            marginLeft: "60px",
                                          }}
                                        >
                                          <Col
                                            className="d-flex"
                                            span={4}
                                            style={{ marginLeft: "7px" }}
                                          >
                                            <div className="mr-fast-15">
                                              <span>Item Name(En): *</span>
                                              <Input
                                                placeholder="Item Name(in English)"
                                                onChange={(e) => {
                                                  this.onChangeFieldData(
                                                    e.target.value,
                                                    "types",
                                                    index,
                                                    "types",
                                                    index1,
                                                    "subTypes",
                                                    index2,
                                                    "product_title"
                                                  );
                                                }}
                                                value={
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1]["subTypes"][index2][
                                                    "product_title"
                                                  ]
                                                }
                                              />
                                              <span style={{ color: "red" }}>
                                                {this.validator.message(
                                                  "Item Name(in English)",
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1]["subTypes"][index2][
                                                    "product_title"
                                                  ],
                                                  "required"
                                                )}
                                              </span>
                                            </div>
                                          </Col>
                                          <Col
                                            className="d-flex"
                                            span={4}
                                            style={{ marginLeft: "7px" }}
                                          >
                                            <div className="mr-fast-15">
                                              <span>Item Name(Ar): *</span>
                                              <Input
                                                placeholder="Item Name(in Arabic)"
                                                onChange={(e) => {
                                                  this.onChangeFieldData(
                                                    e.target.value,
                                                    "types",
                                                    index,
                                                    "types",
                                                    index1,
                                                    "subTypes",
                                                    index2,
                                                    "product_title_ar"
                                                  );
                                                }}
                                                value={
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1]["subTypes"][index2][
                                                    "product_title_ar"
                                                  ]
                                                }
                                              />
                                              <span style={{ color: "red" }}>
                                                {this.validator.message(
                                                  "Item Name(in Arabic)",
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1]["subTypes"][index2][
                                                    "product_title_ar"
                                                  ],
                                                  "required"
                                                )}
                                              </span>
                                            </div>
                                          </Col>
                                          <Col
                                            className="d-flex"
                                            span={4}
                                            style={{ marginLeft: "7px" }}
                                          >
                                            <div className="mr-fast-15">
                                              <span>Item Price(in SAR): *</span>
                                              <Input
                                                placeholder="Item Price(in SAR)"
                                                onChange={(e) => {
                                                  this.onChangeFieldData(
                                                    e.target.value,
                                                    "types",
                                                    index,
                                                    "types",
                                                    index1,
                                                    "subTypes",
                                                    index2,
                                                    "price"
                                                  );
                                                }}
                                                value={
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1]["subTypes"][index2][
                                                    "price"
                                                  ]
                                                }
                                              />
                                              <span style={{ color: "red" }}>
                                                {this.validator.message(
                                                  "Item Price(in SAR)",
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1]["subTypes"][index2][
                                                    "price"
                                                  ],
                                                  "required"
                                                )}
                                              </span>
                                            </div>
                                          </Col>
                                          <Col
                                            className="d-flex"
                                            span={4}
                                            style={{ marginLeft: "7px" }}
                                          >
                                            <div className="mr-fast-15">
                                              <span>Item Calories: </span>
                                              <Input
                                                placeholder="Item Calories"
                                                onChange={(e) => {
                                                  this.onChangeFieldData(
                                                    e.target.value,
                                                    "types",
                                                    index,
                                                    "types",
                                                    index1,
                                                    "subTypes",
                                                    index2,
                                                    "cal"
                                                  );
                                                }}
                                                value={
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1]["subTypes"][index2][
                                                    "cal"
                                                  ]
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col
                                            span={1}
                                            className="add-remove-btn last"
                                            style={{
                                              marginTop: "20px",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            <Button
                                              className="footer-mr-15"
                                              type="primary"
                                              disabled={this.state.disabled}
                                              onClick={() => {
                                                let stateField = { ...fields };
                                                stateField["types"][index][
                                                  "types"
                                                ][index1]["subTypes"].push({
                                                  cal: 0.0,
                                                  product_title: null,
                                                  product_title_ar: null,
                                                  price: null,
                                                });
                                                this.setState({
                                                  fields: { ...stateField },
                                                });
                                              }}
                                            >
                                              +
                                            </Button>
                                          </Col>
                                          <Col
                                            span={1}
                                            style={{
                                              marginTop: "20px",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            <Button
                                              className="footer-mr-15"
                                              type="primary"
                                              disabled={this.state.disabled}
                                              onClick={() => {
                                                let stateField = { ...fields };
                                                if (
                                                  stateField["types"][index][
                                                    "types"
                                                  ][index1]["subTypes"].length >
                                                  1
                                                ) {
                                                  stateField["types"][index][
                                                    "types"
                                                  ][index1]["subTypes"].splice(
                                                    index2,
                                                    1
                                                  );
                                                  this.setState({
                                                    fields: { ...stateField },
                                                  });
                                                }
                                              }}
                                            >
                                              -
                                            </Button>
                                          </Col>
                                        </Row>
                                      </>
                                    ))}
                                  </Row>
                                )}
                              </Row>
                              <Row>
                                {fields["types"][index]["types"][index1][
                                  "select_type"
                                ] == 2 && (
                                  <Row style={{ padding: "1rem" }}>
                                    {fields["types"][index]["types"][index1][
                                      "subTypes"
                                    ].map((b, index2) => (
                                      <>
                                        <Row
                                          style={{
                                            marginBottom: "20px",
                                            marginLeft: "60px",
                                          }}
                                        >
                                          <Col
                                            className="d-flex"
                                            span={4}
                                            style={{ marginLeft: "7px" }}
                                          >
                                            <div className="mr-fast-15">
                                              <span>Item Name(En): *</span>
                                              <Input
                                                placeholder="Item Name(in English)"
                                                onChange={(e) => {
                                                  this.onChangeFieldData(
                                                    e.target.value,
                                                    "types",
                                                    index,
                                                    "types",
                                                    index1,
                                                    "subTypes",
                                                    index2,
                                                    "product_title"
                                                  );
                                                }}
                                                value={
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1]["subTypes"][index2][
                                                    "product_title"
                                                  ]
                                                }
                                              />
                                              <span style={{ color: "red" }}>
                                                {this.validator.message(
                                                  "Item Name(in English)",
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1]["subTypes"][index2][
                                                    "product_title"
                                                  ],
                                                  "required"
                                                )}
                                              </span>
                                            </div>
                                          </Col>
                                          <Col
                                            className="d-flex"
                                            span={4}
                                            style={{ marginLeft: "7px" }}
                                          >
                                            <div className="mr-fast-15">
                                              <span>Item Name(Ar): *</span>
                                              <Input
                                                placeholder="Item Name(in Arabic)"
                                                onChange={(e) => {
                                                  this.onChangeFieldData(
                                                    e.target.value,
                                                    "types",
                                                    index,
                                                    "types",
                                                    index1,
                                                    "subTypes",
                                                    index2,
                                                    "product_title_ar"
                                                  );
                                                }}
                                                value={
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1]["subTypes"][index2][
                                                    "product_title_ar"
                                                  ]
                                                }
                                              />
                                              <span style={{ color: "red" }}>
                                                {this.validator.message(
                                                  "Item Name(in Arabic)",
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1]["subTypes"][index2][
                                                    "product_title_ar"
                                                  ],
                                                  "required"
                                                )}
                                              </span>
                                            </div>
                                          </Col>
                                          <Col
                                            className="d-flex"
                                            span={4}
                                            style={{ marginLeft: "7px" }}
                                          >
                                            <div className="mr-fast-15">
                                              <span>Item Price(in SAR): *</span>
                                              <Input
                                                placeholder="Item Price(in SAR)"
                                                onChange={(e) => {
                                                  this.onChangeFieldData(
                                                    e.target.value,
                                                    "types",
                                                    index,
                                                    "types",
                                                    index1,
                                                    "subTypes",
                                                    index2,
                                                    "price"
                                                  );
                                                }}
                                                value={
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1]["subTypes"][index2][
                                                    "price"
                                                  ]
                                                }
                                              />
                                              <span style={{ color: "red" }}>
                                                {this.validator.message(
                                                  "Item Price(in SAR)",
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1]["subTypes"][index2][
                                                    "price"
                                                  ],
                                                  "required"
                                                )}
                                              </span>
                                            </div>
                                          </Col>
                                          <Col
                                            className="d-flex"
                                            span={4}
                                            style={{ marginLeft: "7px" }}
                                          >
                                            <div className="mr-fast-15">
                                              <span>Item Calories: </span>
                                              <Input
                                                placeholder="Item Calories"
                                                onChange={(e) => {
                                                  this.onChangeFieldData(
                                                    e.target.value,
                                                    "types",
                                                    index,
                                                    "types",
                                                    index1,
                                                    "subTypes",
                                                    index2,
                                                    "cal"
                                                  );
                                                }}
                                                value={
                                                  fields["types"][index][
                                                    "types"
                                                  ][index1]["subTypes"][index2][
                                                    "cal"
                                                  ]
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col
                                            span={1}
                                            className="add-remove-btn last"
                                            style={{
                                              marginTop: "20px",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            <Button
                                              className="footer-mr-15"
                                              type="primary"
                                              disabled={this.state.disabled}
                                              onClick={() => {
                                                let stateField = { ...fields };
                                                stateField["types"][index][
                                                  "types"
                                                ][index1]["subTypes"].push({
                                                  cal: 0.0,
                                                  product_title: null,
                                                  product_title_ar: null,
                                                  price: null,
                                                });
                                                this.setState({
                                                  fields: { ...stateField },
                                                });
                                              }}
                                            >
                                              +
                                            </Button>
                                          </Col>
                                          <Col
                                            span={1}
                                            style={{
                                              marginTop: "20px",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            <Button
                                              className="footer-mr-15"
                                              type="primary"
                                              disabled={this.state.disabled}
                                              onClick={() => {
                                                let stateField = { ...fields };
                                                if (
                                                  stateField["types"][index][
                                                    "types"
                                                  ][index1]["subTypes"].length >
                                                  1
                                                ) {
                                                  stateField["types"][index][
                                                    "types"
                                                  ][index1]["subTypes"].splice(
                                                    index2,
                                                    1
                                                  );
                                                  this.setState({
                                                    fields: { ...stateField },
                                                  });
                                                }
                                              }}
                                            >
                                              -
                                            </Button>
                                          </Col>
                                        </Row>
                                      </>
                                    ))}
                                  </Row>
                                )}
                              </Row>
                            </>
                          ))}
                        </Row>
                      )}
                    </div>
                  ))}
              </div>
            </Row>
          </div>

          <Row>
            {/* select category */}
            <Col style={{ marginBottom: "15px" }}>
              <span style={{ display: "block" }}>Category: *</span>
              <Select
                showSearch
                style={{
                  width: "100%",
                  marginRight: "15px",
                }}
                placeholder="Select Category"
                optionFilterProp="children"
                value={this.state.selctedCategory}
                onChange={this.onChangeCategory}
                onFocus={this.onFocusCategory}
                onBlur={this.onBlurCategory}
                onSearch={this.onSearchCategory}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.state.allCategories.map(function (temp) {
                  return <Option value={temp.id}>{temp.name}</Option>;
                })}
              </Select>
              <span style={{ color: "red" }}>
                {this.validator.message(
                  "Food Category",
                  this.state.selctedCategory,
                  "required"
                )}
              </span>
            </Col>
          </Row>

          <Row>
            {/* select subcategory */}
            <Col style={{ marginBottom: "15px" }}>
              <span style={{ display: "block" }}>Sub Category: *</span>
              <Select
                mode="multiple"
                showSearch
                style={{ width: "100%", marginTop: "10px" }}
                value={this.state.selctedSubCategory}
                placeholder="Select Sub Categories"
                optionFilterProp="children"
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.state.allSubCategories.map(function (temp) {
                  return <Option value={temp.id}>{temp.name}</Option>;
                })}
              </Select>
              <span style={{ color: "red" }}>
                {this.validator.message(
                  "Food Sub Category",
                  this.state.selctedSubCategory,
                  "required"
                )}
              </span>
            </Col>
          </Row>

          {loader && (
            <span className="loader-class">
              <Spin />
            </span>
          )}
        </Modal>
      </div>
    );
  }
}

export default connect((state) => ({
  user: state.data.get("user"),
}))(EditProductModal);
