import React from 'react';
import {Link} from 'react-router-dom'

class notFound extends React.Component {
    render () {
        return(
            <div>404! - <Link to="/">Go to Homepage!</Link></div>
        );
    }
}

export default notFound;

