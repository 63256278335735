import { deleteFCMToken } from "../../config/firebase";
import { LOGOUT, SHOW_NEW_ORDER, LOGIN_SUCCESS } from "./types";

export const logout = () => async (dispatch) => {
  await deleteFCMToken();
  dispatch({
    type: LOGOUT,
  });
};

export const updateNewOrder = (order_id) => async (dispatch) => {
  dispatch({
    type: SHOW_NEW_ORDER,
    order_id: order_id,
  });
};

export const login = (user) => async (dispatch) => {
  dispatch({
    type: LOGIN_SUCCESS,
    user: user,
  });
};
