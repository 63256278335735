import React from "react";
import "./completedOrders.css";
import DatePicker from "react-datepicker";
import FoodcourtLoader from "../foodcourtLoader";
import { withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import { doneTypingInterval } from "../utility/const";
import { formatDate } from "../utility/util";
import { connect } from "react-redux";
import { login, logout } from "../../redux/actions";
import { database, ordersRef } from "../../config/firebase";
import { onValue, ref } from "firebase/database";
import Api from "../../helper/apiHandler";

const api = new Api();
var moment = require("moment");

class CompletedOrders extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      expandedRows: [],
      isRowCurrentlyExpanded: false,
      isopen: [],
      startDate: "",
      search: "",
      endDate: "",
      totalPages: "",
      loader: false,
      completedOrdersData: [],
      totalCompletedOrders: "",
      statusType: "completed",
      statusLabel: "Completed",
      currentPage: 0,
    };
    this.typingTimer = undefined;
    this.doneTypingInterval = doneTypingInterval;
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.getcompletedOrders = this.getcompletedOrders.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.setState(
      {
        userdata: this.props.user,
      },
      () => {
        const ordersRef = ref(
          database,
          `counters/${this.state.userdata.id}/completed_orders`
        );
        onValue(ordersRef, (snapshot) => {
          this.getcompletedOrders();
        });
      }
    );
    // ordersRef
    //   .child(`${this.props.user.id}/completed_orders`)
    //   .on("child_changed", (snapshot) => {
    //     this.getcompletedOrders();
    //   });
  }

  getcompletedOrders() {
    const { search, startDate, endDate, currentPage } = this.state;
    const values = {
      name: search,
      beginDate: startDate ? formatDate(startDate) + " 00:00:00" : "",
      endDate: endDate ? formatDate(endDate) + " 23:59:59" : "",
    };
    let _this = this;
    _this.setState({ loader: true });
    api
      .post(`/rest/list-completed-orders?page=${currentPage}`, {
        data: values,
      })
      .then(function (res) {
        if (res.status === 200) {
          if (Object.keys(res.data).length !== 0) {
            _this.setState({
              completedOrdersData: res.data.order_list,
              totalPages: res.data.totalPages,
              loader: false,
            });
          } else {
            _this.setState({
              completedOrdersData: [],
              totalPages: "",
              loader: false,
            });
          }
        } else if (res.response.data.status === 401) {
          // _this.openNotificationWithIcon("error", "Error", res.error);
          _this.props.history.push("/");
          _this.props.logout();
        } else {
          _this.setState({ loader: false });
          _this.openNotificationWithIcon("error", "Error", res.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onChange = (event) => {
    clearTimeout(this.typingTimer);
    this.setState({ search: event.target.value });
    this.typingTimer = setTimeout(() => {
      this.getcompletedOrders();
    }, this.doneTypingInterval);
  };

  handleRowClick(rowId) {
    const temp = { ...this.state.isopen };
    temp[rowId] = true;
    this.setState({ isopen: temp });

    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);
    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowId)
      : currentExpandedRows.concat(rowId);

    this.setState({
      expandedRows: newExpandedRows,
      isRowCurrentlyExpanded: isRowCurrentlyExpanded,
    });
  }

  handleCloseClick(rowId) {
    const temp = { ...this.state.isopen };
    temp[rowId] = false;
    this.setState({ isopen: temp });

    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);
    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowId)
      : currentExpandedRows.concat(rowId);

    this.setState({
      expandedRows: newExpandedRows,
      isRowCurrentlyExpanded: isRowCurrentlyExpanded,
    });
  }

  renderItem(item) {
    const { isopen } = this.state;
    const clickCallbackFalse = () => this.handleCloseClick(item.id);
    const clickCallback = () => this.handleRowClick(item.id);
    const itemRows = [
      <tr className="main-accordion-row" key={"row-data-" + item.id}>
        <td className="orderid">#{item.foodcourt_order_id}</td>
        <td>
          <span className="user-image">
            {item.user_image === "" ? (
              <img src="/food-court/images/default-image.png" />
            ) : (
              <img src={item.user_image} />
            )}
          </span>
          {item.user_name}
        </td>
        <td>
          {moment.utc(item.updated_at).local().format("MMM DD, YYYY, hh:mm A")}
        </td>
        <td>{item.items}</td>
        <td className="status">
          <span className={this.state.statusType}>
            {this.state.statusLabel}
          </span>
        </td>
        <td className="actions">
          {!isopen[item.id] && (
            <span className="view" onClick={clickCallback}>
              View
            </span>
          )}
          {isopen[item.id] && (
            <span className="close" onClick={clickCallbackFalse}>
              Close
            </span>
          )}
        </td>
      </tr>,
    ];

    if (this.state.expandedRows.includes(item.id)) {
      let subItemData = item.productDisplayDtoList;
      const subItemRows = [];
      subItemData.forEach((subItem) => {
        const subItemRow = [
          <tr
            key={"sub-row-" + subItem.id}
            className="accordion-content-row-sub"
          >
            <td>
              <span className="food-image">
                {subItem.image === "" ? (
                  <img
                    src="/food-court/images/default-image.png"
                    // alt={"user" + subItem.id}
                  />
                ) : (
                  <img src={subItem.image} />
                )}
              </span>
              {subItem.name}
            </td>
            {subItem.selected_add_ons.length > 0 ? (
              <td style={{ textAlign: "left" }}>
                {subItem.selected_add_ons &&
                  subItem.selected_add_ons.map((e, index) => (
                    <>
                      <b key={e.id}>{e.title}: </b>
                      {e.types &&
                        e.types.map((x, index1) => (
                          <>
                            <>
                              <span key={x.id}>{x.option_title}</span>
                              {index1 + 1 === e.types.length ? <br /> : ","}
                            </>
                            {x.sub_types_option_title && (
                              <>
                                <b>{x.sub_types_option_title}: </b>
                                {x.sub_types &&
                                  x.sub_types.map((y, index2) => (
                                    <>
                                      <span key={y.id}>{y.product_title}</span>
                                      {index2 + 1 === x.sub_types.length ? (
                                        <br />
                                      ) : (
                                        ","
                                      )}
                                    </>
                                  ))}
                              </>
                            )}
                          </>
                        ))}
                    </>
                  ))}
              </td>
            ) : (
              <td style={{ textAlign: "left", width: "50%" }}>-</td>
            )}
            <td>{subItem.quantity}</td>
          </tr>,
        ];
        subItemRows.push(subItemRow);
      });

      itemRows.push(
        <tr key={"row-expanded-" + item.id} className="accordion-content-row">
          <td colSpan="6">
            <div className="inner-table-main">
              <table>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th style={{ textAlign: "left", padding: "0px" }}>
                      Specifications
                    </th>
                    <th>Qty</th>
                  </tr>
                </thead>
                <tbody>{subItemRows}</tbody>
              </table>
            </div>
          </td>
        </tr>
      );
    }
    return itemRows;
  }

  handleStartDateChange(date) {
    this.setState({
      startDate: date,
    });
  }

  handleEndDateChange(date) {
    this.setState({
      endDate: date,
    });
  }

  handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * this.props.perPage);
    console.log("selected", selected);
    this.setState({ offset: offset, currentPage: selected }, () => {
      this.getcompletedOrders();
    });
  };
  resetFilter = () => {
    this.setState(
      { search: "", startDate: undefined, endDate: undefined },
      () => this.getcompletedOrders()
    );
  };

  render() {
    let allItemRows = [];

    let { completedOrdersData } = this.state;

    completedOrdersData.forEach((item) => {
      const perItemRows = this.renderItem(item);
      allItemRows = allItemRows.concat(perItemRows);
    });

    return (
      <>
        {this.state.loader == true ? <FoodcourtLoader /> : ""}
        <div className="completed-orders">
          <div className="top-head-component">
            <div className="search-section">
              <input
                type="search"
                placeholder="Search by name"
                onChange={this.onChange}
              />
            </div>
            <div className="filter-section">
              <label>Filter By Dates</label>
              <DatePicker
                selected={this.state.startDate}
                onChange={this.handleStartDateChange}
                placeholderText="From"
                maxDate={new Date()}
              />
              <DatePicker
                selected={this.state.endDate}
                onChange={this.handleEndDateChange}
                placeholderText="To"
                minDate={this.state.startDate}
                maxDate={new Date()}
              />
              <button className="apply-btn" onClick={this.getcompletedOrders}>
                Apply
              </button>
              <button
                className="apply-btn btn-reset"
                onClick={this.resetFilter}
              >
                Reset
              </button>
            </div>
          </div>
          <div className="table-section">
            <table className="main-table">
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Item</th>
                  <th>status</th>
                  <th>action</th>
                </tr>
              </thead>
              {completedOrdersData.length === 0 ? (
                <tbody>
                  <tr>
                    <td className="no_data" colSpan="6">
                      No orders found
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>{allItemRows}</tbody>
              )}
            </table>
            {this.state.totalPages > 0 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={parseInt(this.state.totalPages)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => {
    return {
      user: state.data.get("user"),
    };
  },
  { login, logout }
)(withRouter(CompletedOrders));
