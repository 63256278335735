import { Map } from "immutable";
import {
  FETCH_FIREBASE_NOTIFICATIONS,
  LOGOUT,
  SHOW_NEW_ORDER,
  LOGIN_SUCCESS,
} from "../actions/types";
const initState = new Map({
  notifications: null,
  notification_id: null,
  user: null,
});

export default (state = initState, action) => {
  switch (action.type) {
    case FETCH_FIREBASE_NOTIFICATIONS:
      return state.set("notifications", action.payload);
    case LOGIN_SUCCESS:
      return state.set("user", action.user).slice();
    case LOGOUT:
      return initState;
    case SHOW_NEW_ORDER:
      return state.set("notification_id", action.order_id);
    default:
      return state;
  }
};
