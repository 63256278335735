import React from "react";
import Header from "../header/header";
import BannerComponent from "../bannerComponent/bannerComponent";
import "./dashboard.css";
import { withRouter } from "react-router-dom";
import "antd/dist/antd.css";
import Sidebar from "../Sidebar/Sidebar";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <BannerComponent />;
  }
}

export default withRouter(Dashboard);

// class Dashboard extends React.Component {
//   constructor(props) {
//     super(props);
//   }
//   render() {
//     return (
//       <div style={{ display: "flex" }}>
//         <div style={{ flex: 1 }} className="dashboard-page">
//           <BannerComponent />
//         </div>
//       </div>
//     );
//   }
// }
