import React from "react";
import Switch from "react-switch";
import Card from "./card";
import "./bannerComponent.css";
import { notification, Menu, Dropdown, Icon } from "antd";
import { withRouter } from "react-router-dom";
import FoodcourtLoader from "../foodcourtLoader";
import { connect } from "react-redux";
import { login, logout } from "../../redux/actions";
import Api from "../../helper/apiHandler";

const api = new Api();

class BannerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { checked: true, userdata: props.user, loader: false };
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
  }
  componentDidMount() {
    if (this.state.userdata.is_online === 0) {
      alert("ksajh");
      document.body.classList.add("offline_mode");
      this.setState({ checked: false });
    } else {
      document.body.classList.remove("offline_mode");
      this.setState({ checked: true });
    }
  }
  handleSwitchChange(checked) {
    if (checked === true) {
      // alert("Online");
      // document.body.classList.remove("offline_mode");
      // this.openNotificationWithIcon(
      //   "success",
      //   "Success",
      //   "You are now Online."
      let _this = this;
      _this.setState({ loader: true });
      api
        .post(`/rest/set-restaurant-online`, {
          data: {},
        })
        .then(function (res) {
          if (res.status === 200) {
            _this.setState({
              loader: false,
            });
            // _this.setState({ checked });
            var temp = _this.props.user;
            temp["is_online"] = 1;
            _this.props.login(temp);
            _this.setState({
              userdata: _this.props.user,
              checked,
            });
            _this.openNotificationWithIcon("success", "Success", res.message);
          } else if (res.response.data.status === 401) {
            // _this.openNotificationWithIcon("error", "Error", res.error);
            _this.setState({ loader: false });
            _this.props.history.push("/");
            _this.props.logout();
          } else if (res.status === 500) {
            _this.openNotificationWithIcon("error", "Error", res.message);
            _this.setState({ loader: false });
          } else {
            _this.setState({ loader: false });
            _this.openNotificationWithIcon("error", "Error", res.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // );
    } else {
      // alert("Offline");
      // document.body.classList.add("offline_mode");
      // this.openNotificationWithIcon(
      //   "success",
      //   "Success",
      //   "You are now Offline."
      // );
      let _this = this;
      _this.setState({ loader: true });
      api
        .post(`/rest/set-restaurant-offline`, {
          data: {},
        })
        .then(function (res) {
          if (res.status === 200) {
            _this.setState({
              loader: false,
            });

            var temp = _this.props.user;
            temp["is_online"] = 0;
            _this.props.login(temp);
            _this.setState({ userdata: _this.props.user, checked });
            _this.openNotificationWithIcon("success", "Success", res.message);
          } else if (res.response.data.status === 401) {
            // _this.openNotificationWithIcon("error", "Error", res.error);
            _this.setState({ loader: false });
            _this.props.history.push("/");
            _this.props.logout();
          } else if (res.status === 500) {
            _this.openNotificationWithIcon("error", "Error", res.message);
            _this.setState({ loader: false });
          } else {
            _this.setState({ loader: false });
            _this.openNotificationWithIcon("error", "Error", res.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // fetch(API_URL + "/rest/set-restaurant-offline", {
    //   method: "post",
    //   headers: {
    //     // Accept: "application/json",
    //     "Content-Type": "application/json",
    //     restaurantId: this.state.userdata.id,
    //     authorisation: this.state.userdata.jwt_token
    //   }
    // })
    //   .then(response => response.json())
    //   .then(responseData => {
    //     if (responseData.status === 200) {
    //       this.setState({
    //         loader: false
    //       });
    //       this.setState({ checked });
    //       if (checked === true) {
    //         // alert("Online");
    //         document.body.classList.remove("offline_mode");
    //         this.openNotificationWithIcon(
    //           "success",
    //           "Success",
    //           "You are now Online."
    //         );
    //       } else {
    //         // alert("Offline");
    //         document.body.classList.add("offline_mode");
    //         this.openNotificationWithIcon(
    //           "success",
    //           "Success",
    //           "You are now Offline."
    //         );
    //       }
    //     } else if (responseData.status === 401) {
    //       this.openNotificationWithIcon("error", "Error", responseData.error);
    //       this.setState({ loader: false });
    //       this.props.history.push("/");
    //       localStorage.clear("USERDATA");
    //     } else if (responseData.status === 500) {
    //       this.openNotificationWithIcon("error", "Error", responseData.message);
    //       this.setState({ loader: false });
    //     } else {
    //       console.log("this.props", this.props);
    //       this.setState({ loader: false });
    //       this.openNotificationWithIcon("error", "Error", responseData.error);
    //     }
    //   })
    //   .catch(error => {
    //     console.log("this is error", error);
    //   });
  }
  openNotificationWithIcon(type, head, desc) {
    notification[type]({
      message: head,
      description: desc,
      duration: 6,
    });
  }
  render() {
    return (
      <div className="banner-main wrapper-main">
        <div className="banner-head">
          <h2 className="banner-head-left">
            <span className="zone">Food Zone </span>
            <span className="seperator"> - </span>
            <span className="rest-name">{this.state.userdata.zone}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <img
              src={
                "https://openxcell-development-public.s3.ap-south-1.amazonaws.com/food_court/" +
                this.state.userdata.image
              }
            ></img>
          </h2>
          <div className="banner-head-right">
            <label htmlFor="icon-switch">
              <Switch
                checked={this.state.userdata.is_online}
                onChange={this.handleSwitchChange}
                uncheckedIcon={
                  <div
                    className={
                      this.state.userdata.is_online ? "inactive" : "active"
                    }
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                      fontSize: 16,
                      color: "#4d4f5c",
                    }}
                  >
                    Offline
                  </div>
                }
                checkedIcon={
                  <div
                    className={
                      this.state.userdata.is_online ? "active" : "inactive"
                    }
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                      fontSize: 16,
                      color: "#4d4f5c",
                    }}
                  >
                    Online
                  </div>
                }
                className="react-switch"
                id="icon-switch"
              />
            </label>
          </div>
        </div>
        <Card checked={this.state.checked} />
        {this.state.loader == true ? <FoodcourtLoader /> : ""}
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      user: state.data.get("user"),
    };
  },
  { login, logout }
)(withRouter(BannerComponent));
