import { createStore, applyMiddleware, compose } from "redux";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import reducers from "../reducers";
import { loadState, saveState } from "./localstorage";

const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_PATH,
});
const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];
const persistedState = loadState();

const store = createStore(
  reducers,
  persistedState,
  compose(applyMiddleware(...middlewares))
);

store.subscribe(() => {
  saveState(store.getState());
});

export { store, history };
