import React from "react";
import logo from "../../assets/images/login-page-logo.png";
import SimpleReactValidator from "simple-react-validator";
import FoodcourtLoader from "../../components/foodcourtLoader";
import { notification } from "antd";
import "antd/dist/antd.css";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Api from "../../helper/apiHandler";

const api = new Api();

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
    };
    this.validator1 = new SimpleReactValidator();
    this.submit = this.submit.bind(this);
    this.clearValidation = this.clearValidation.bind(this);
  }
  componentDidMount() {
    this.setState({
      userdata: this.props.user,
    });
  }

  changeFields(e, field) {
    this.setState({
      [field]: e.target.value,
    });
  }

  changeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }
  clearValidation() {
    this.validator1.hideMessages();
    this.forceUpdate();
  }
  submit(e) {
    e.preventDefault();

    if (this.validator1.allValid()) {
      // alert("You submitted the form and stuff!");
      // this.setState({ loader: true });
      var values = {
        old_password: this.state.current_password,
        new_password: this.state.new_password,
        restaurant_user_id: this.state.userdata.id,
        restaurant_user_email: this.state.userdata.email,
      };
      let _this = this;
      _this.setState({ loader: true });
      api
        .post(`/rest/change-password-restaurant-user`, {
          data: values,
        })
        .then(function (res) {
          if (res.status === 200) {
            _this.openNotificationWithIcon("success", "Success", res.message);
            _this.setState({ loader: false, email: "" });
            _this.clearValidation();
          } else {
            _this.openNotificationWithIcon("error", "Error", res.message);
            _this.setState({ loader: false });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.validator1.showMessages();
      this.forceUpdate();
    }
  }

  openNotificationWithIcon(type, head, desc) {
    notification[type]({
      message: head,
      description: desc,
      duration: 6,
    });
  }

  render() {
    return (
      <div
        style={{ textAlign: "center", margin: "0 auto" }}
        className="login-page-inner"
      >
        {this.state.loader == true ? <FoodcourtLoader /> : ""}
        <div className="logo">
          {/* <img src={logo} alt="Food Court" /> */}
          <p>Change Password</p>
        </div>
        <form className="forgot-password-form">
          <div className="floating-form">
            <div className="floating-label">
              <input
                className="floating-input"
                type="password"
                placeholder="Current Password"
                value={this.state.current_password}
                onChange={(e) => this.changeFields(e, "current_password")}
              />
              {this.validator1.message(
                "Current Password",
                this.state.current_password,
                "required|min:6|max:50",
                "text-danger"
              )}
              <label className="label_float">Current Password</label>
            </div>
            <div className="floating-label">
              <input
                className="floating-input"
                type="password"
                placeholder="New Password"
                value={this.state.new_password}
                onChange={(e) => this.changeFields(e, "new_password")}
              />
              {this.validator1.message(
                "New Password",
                this.state.new_password,
                "required|min:6|max:50",
                "text-danger"
              )}
              <label className="label_float">New Password</label>
            </div>
            <div className="floating-label">
              <input
                className="floating-input"
                type="text"
                placeholder="Confirm Password"
                value={this.state.confirm_password}
                onChange={(e) => this.changeFields(e, "confirm_password")}
              />
              {this.validator1.message(
                "Confirm Password",
                this.state.confirm_password,
                `required|in:${this.state.new_password}|min:6|max:50`,
                { messages: { in: "Passwords need to match!" } }
              )}
              <label className="label_float">Confirm Password</label>
            </div>
          </div>
          {/* <div className="last-section last-section-forgot">
              <div className="link-forgot">
                <Link to="/dashboard">Back to Dashboard</Link>
              </div>
            </div> */}
          <button onClick={this.submit} className="login-btn">
            Change Password
          </button>
        </form>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    user: state.data.get("user"),
  };
})(withRouter(ChangePassword));
